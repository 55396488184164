import * as React from "react";
import { useSnackbar } from "notistack";
interface ImageUploadButton {
    className?: string;
    maxResolution?: { width: number; height: number };
    maxFileSize?: number;
    onFileSelect?: (file: File | null) => void;
}

export default function ImageUploadButton({ className, maxResolution, maxFileSize, onFileSelect }: ImageUploadButton) {
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const { enqueueSnackbar } = useSnackbar();
    const validateImage = async (file: File): Promise<boolean> => {
        if (maxFileSize && file.size > maxFileSize * 1024*1024) {
            enqueueSnackbar(`File size must be less than ${maxFileSize}MB`, { variant: 'error', autoHideDuration: 1000 });
            return false;
        }
        return true;
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const isValid = await validateImage(file);
        if (isValid) {
            onFileSelect?.(file);
        }
       
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <div 
            className={`${className} hover:cursor-pointer`} 
            onClick={() => fileInputRef.current?.click()}
        >
            <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleFileUpload}
            />
            <div className="w-[46px] h-[41px]">
                <img src="/assets/upload-icon.svg" alt="Upload Icon" />
            </div>
            <div className="flex flex-col items-center text-[13px] font-spline-sans-mono pt-[5px]">
                <span className="line-height-1">{maxResolution?.width}x{maxResolution?.height}</span>
                <span className="line-height-1"> {maxFileSize ? `<${maxFileSize}MB` : ''}</span>
            </div>
        </div>
    );
}