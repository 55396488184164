import * as React from "react";
import Header from "@/components/template/dashboard-layout/header";
import Footer from "@/components/template/dashboard-layout/footer";
import Owner from "@/components/organisms/owner";
import { useParams } from "react-router-dom";
import { backend } from "@/services/backend";
import { useEffect, useState } from "react";
import { OwnerData } from "@/types";

export default function OwnerPage() {
    const { address } = useParams();
    console.log(address, 'address');
    const [ownerData, setOwnerData] = useState<OwnerData | null>(null);
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const fetchOwnerData = async () => {
            if (!address) return;
            const ownerData = await backend.getOwnerData(address);
            console.log(ownerData, 'ownerData');
            setOwnerData(ownerData);
        };
        fetchOwnerData();
    }, [address]);

    return (
        <>
            <Header />
              <Owner ownerData={ownerData} />
            <Footer />
        </>
    )
}