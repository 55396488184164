import * as React from "react";
interface StatCardProps {
    className?: string;
    title: string;
    value: number;
    subtitle: string;
    isEditView: boolean;
    isVisible: boolean;
    onChange: () => void
    isOwnerView: boolean;
  }
  
  export default function StatCard ({className, title, value, subtitle, isEditView, isVisible, onChange, isOwnerView }: StatCardProps) {

    return (
        <div className={`${className} flex flex-col items-center justify-center relative ${!isOwnerView && !isVisible ? 'hidden' : ''}`}>
            {isEditView && (
                <div 
                onClick={onChange}
                className="cursor-pointer absolute top-[-25px]"
            >
                {isVisible ? (
                    // Visible eye SVG (green)
                    <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.03361 12.1344H6.06722V15.1681H9.10084V18.2017H12.1344H15.1681H18.2017V15.1681H21.2353V12.1344H24.2689V9.10084H21.2353V6.06722H18.2017V3.03361V0H15.1681H12.1344H9.10084V3.03361V6.06722H6.06722V9.10084H3.03361V12.1344ZM21.2353 6.06722H24.2689V9.10084H27.3025V12.1344H24.2689V15.1681H21.2353V18.2017H18.2017V21.2353H15.1681H12.1344H9.10084V18.2017H6.06722V15.1681H3.03361V12.1344H0V9.10084H3.03361V6.06722H6.06722V3.03361H9.10084H12.1344H15.1681H18.2017H21.2353V6.06722ZM15.1378 12.1344V13.1524V15.1681H12.1042V13.1524V12.1344H9.0706V9.10084H12.1042V7.58907V6.06722H15.1378V7.58907V9.10084H18.1714V12.1344H15.1378Z" fill="#00FF2F"/>
                    </svg>
                ) : (
                    // Hidden eye SVG (red) - you can replace this with your preferred hidden eye icon
                    <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path fillRule="evenodd" clipRule="evenodd" d="M3.03361 12.1344H6.06722V15.1681H9.06052V12.1344H12.0941H15.1277V9.10084H18.1614V6.06722H15.1277V9.10084H12.0941V12.1344V15.1681H9.10084V18.2017H12.1344H15.1681H18.2017V15.1681H21.2353V12.1344H24.2689V9.10084H21.2353V6.06722H18.2017V3.03361V0H15.1681H12.1344H9.10084V3.03361V6.06722H6.06722V9.10084H3.03361V12.1344ZM3.03361 18.2017H6.06722V15.1681H3.03361V12.1344H0V9.10084H3.03361V6.06722H6.06722V3.03361H9.10084H12.1344H15.1681H18.2017H21.2353V0H24.2689V3.03361H21.2353V6.06722H24.2689V9.10084H27.3025V12.1344H24.2689V15.1681H21.2353V18.2017H18.2017V21.2353H15.1681H12.1344H9.10084V18.2017H6.06722V21.2353H3.03361V18.2017Z" fill="#FF0000"/>
                    </svg>
                    )}
                </div>
            )}

            <span className={`text-[24px] text-[#0099FE] ${!isVisible && 'opacity-50'}`}>{title}</span>
            <span className={`text-[32px] ${!isVisible && 'opacity-50'}`}>{value}</span>
            <span className={`text-[16px] text-[#0099FE] ${!isVisible && 'opacity-50'}`}>{subtitle}</span>
        
        </div>
    )
}   

