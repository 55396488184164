
export const exampleOwnerData = {
     background: "/assets/owner-bg.png",
     avatar: "/assets/avatar.png",
     name: "@punk_monsters",
     address: "OGDREe1UU#78vR34m2gl232323IL3l",
     stats: [
        { title: 'CREATED', value: 2475, subtitle: 'COLLECTIONS', isVisible: true },
        { title: 'MINTED', value: 3579, subtitle: 'TON', isVisible: true },
        { title: 'EARNED', value: 3579, subtitle: 'TON', isVisible: true },
     ],
     info: "Pixel art game background with button level up. Game design concept in retro style. Vector illustration. Game screen pixelPixel art game background with button level up. Game design concept in retro style. Vector illustration. Game screen pixelPixel art game background with button level up. Game design concept in retro style. Vector illustration. Game screen pixelPixe  l art game background",
     socials: [
        { type: 'telegram', url: "https://t.me/punk_monsters" },
        { type: 'rocket', url: "https://t.me/punk_monsters" },
        { type: 'twitter', url: "https://twitter.com/punk_monsters" },
        { type: 'spa', url: "https://punkmonsters.io" }
     ],
     collections: [
        {
            image: "/assets/avatar.png", 
            name: "PUNK_MsfdfERS", 
            value: 1289, 
            totalMintCount: 9999, 
            currentMintedCount: 9998
        },
        {
            image: "/assets/avatar2.png", 
            name: "PUNK_MsfdfERS", 
            value: 1289, 
            totalMintCount: 9999, 
            currentMintedCount: 9998
        },
        {
            image: "/assets/avatar3.png", 
            name: "PUNK_MsfdfERS", 
            value: 1289, 
            totalMintCount: 9999, 
            currentMintedCount: 9998
        },
        {
            image: "/assets/avatar.png", 
            name: "PUNK_MsfdfERS", 
            value: 1289, 
            totalMintCount: 9999, 
            currentMintedCount: 9998
        },
        {
            image: "/assets/avatar.png", 
            name: "PUNK_MsfdfERS", 
            value: 1289, 
            totalMintCount: 9999, 
            currentMintedCount: 9998
        },
        {
            image: "/assets/avatar.png", 
            name: "PUNK_MsfdfERS", 
            value: 1289, 
            totalMintCount: 9999, 
            currentMintedCount: 9998
        }
     ],
     nfts: [
        {
            image: "/assets/avatar.png", 
            name: "PUNK_MsfdfERS",
            id: "#6586",
            ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
            price: 10
        },
        {
            image: "/assets/avatar.png", 
            name: "PUNK_MsfdfERS",
            id: "#6586",
            ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
            price: 10
        },  
        {
            image: "/assets/avatar.png", 
            name: "PUNK_MsfdfERS",
            id: "#6586",
            ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
            price: 10
        },
        {
            image: "/assets/avatar.png", 
            name: "PUNK_MsfdfERS",
            id: "#6586",
            ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
            price: 10
        },
        {
            image: "/assets/avatar.png", 
            name: "PUNK_MsfdfERS",
            id: "#6586",
            ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
            price: 10
        },  
        {
            image: "/assets/avatar.png", 
            name: "PUNK_MsfdfERS",
            id: "#6586",
            ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
            price: 10
        },
        {
            image: "/assets/avatar.png", 
            name: "PUNK_MsfdfERS",
            id: "#6586",
            ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
            price: 10
        },
        {
            image: "/assets/avatar.png", 
            name: "PUNK_MsfdfERS",
            id: "#6586",
            ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
            price: 10
        },  
        {
            image: "/assets/avatar.png", 
            name: "PUNK_MsfdfERS",
            id: "#6586",
            ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
            price: 10
        },
     ]
}