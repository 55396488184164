import { Address } from "@ton/core";

export const miniappUrlParamSeparator = "___"

// Call to generate link
export function generateMiniAppParam (path: string, ref?: string) {
    if (ref)
        try {
            Address.parse(ref);  // Validate address if ref is provided
        } catch (e) {
            return "";  // Return empty if ref is invalid
        }
    
    let url_param = path.replace("/", "_");  // Replace '/' with '_'

    if (ref) {
        url_param = url_param + `${miniappUrlParamSeparator}ref__${ref}`;  // Append ref if present
    }

    return url_param;
}

// Call to use link
export function getUrlFromMiniAppParam (param: string) {
    if (!param) return "";
    const paramStr = param.split(miniappUrlParamSeparator);  // Split by separator

    let url = paramStr[0].replace("_", "/");  // Replace '_' back to '/'
    
    if (paramStr.length > 1) {
        url = url + paramStr[1].replace("ref__", "?ref=");  // Append ref query if present
    }

    return url;
}