import * as React from "react";

interface DailyNewsCardType  {
    className: string
    title: string
    description: string
    image: string
}
export default function DailyNewsCard({ title, description, image, className }: DailyNewsCardType) {
    return(
        <div className={className}>
            <div className="w-full aspect-square">
                <img src={image} alt={title} />
            </div>
            <h1 className='font-silkscreen pt-[10px] text-[14px] text-white'>{title}</h1>
            <p className='pt-[20px] font-spline-sans-mono text-[10px] text-[#757575]'>{description}</p>
        </div>
    )
}