import {
  CollctionDataType,
  CollectionMasterDataType,
  CollectionDataCache,
  ItemDataType,
  MasterDataType,
  OwnerData,
} from "../types";
import { BACKEND_URL } from "../config";

class BackendService {
  private headers: Record<string, string> = {};

  private static instance: BackendService;

  constructor(headers: Record<string, string>) {
    this.headers = headers;
  }

  private setAccessToken(accessToken: string) {
    this.headers.Authorization = `${accessToken}`;
  }

  static getInstance(): BackendService {
    if (!BackendService.instance) {
      BackendService.instance = new BackendService({});
      const bearerToken = localStorage.getItem("bearerToken");
      if (bearerToken) {
        BackendService.instance.setAccessToken(bearerToken);
      }
    }
    return BackendService.instance;
  }

  private async get(path: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = `${BACKEND_URL}${path}`;

        const res = await fetch(endpoint, {
          method: "GET"
        });
        const json: any = await res.json();
        resolve(json);
      } catch (e: any) {
        console.log(Error(e));
        reject(e);
      }
    });
  }

  private async post<T>(
    path: string,
    body: T,
    method?: "POST" | "PUT" | "PATCH"
  ) {
    try {
      const endpoint = `${BACKEND_URL}${path}`;

      const response = await fetch(endpoint, {
        method,
        mode: "cors",
        credentials: "include",
        body: JSON.stringify(body),
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(json?.error);
      }

      return await response.json();
    } catch (e: any) {
      throw new Error(e);
    }
  }

  public async getItemData(address: string): Promise<ItemDataType | null> {
    return new Promise((resolve) => {
      this.get(`item/${address}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });
  }

  public async getCollectionData(
    address: string
  ): Promise<CollectionDataCache | null> {
    return new Promise((resolve) => {
      this.get(`collectiondata/${address}`)
        .then((result) => {
          resolve(result.collection);
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });
  }

public async getOwnerData(
    address: string
  ): Promise<OwnerData| null> {
    return new Promise((resolve) => {
      this.get(`owner/${address}`)
        .then((result) => {
          resolve(result);
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });
  }

  public async getMasterData(address: string): Promise<MasterDataType | null> {
    return new Promise((resolve) => {
      this.get(`master/${address}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });
  }

  public async getCollectionMasterData(
    address: string
  ): Promise<CollectionMasterDataType | null> {
    return new Promise((resolve) => {
      this.get(`collectionmaster/${address}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });
  }

  public async getDeployStatus(address: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.get(`isdeploy/${address}`)
        .then((result) => {
          resolve(result.deploy || false);
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  }
}

export const backend = BackendService.getInstance();
