import * as React from "react";
import { GoPlus } from "react-icons/go";
import { FiMinus } from "react-icons/fi";

export type MetaDataType = {
    label: string;
    pairs: Array<{ key: string; value: string }>;
    isLoading?: boolean;
    onAddPair: () => void;
    onRemovePair: (index: number) => void;
    onUpdatePair: (index: number, field: 'key' | 'value', value: string) => void;
    onHelpClick?: () => void;
};

export default function MetaData({
    label, 
    pairs,
    onAddPair,
    onRemovePair,
    onUpdatePair,
    isLoading,
    onHelpClick
}: MetaDataType) {
    return (
        <div className={`${isLoading ? 'text-[#484848]' : ''} relative text-[24px] text-center pb-[10px]`}>
            {label}
            <div className="absolute right-0 top-0">
                <span className={`${isLoading ? 'text-[#484848]' : 'text-[#0099FE]'}`} onClick={onHelpClick}>?</span>
            </div>

            {pairs.map((pair, index) => (
                <div key={index} className="mb-4">
                    <div className="relative flex gap-[4px]">
                        <input 
                            className={`${isLoading ? 'text-[#484848] cursor-not-allowed' : ''} w-[calc(50%-2px)] p-[10px] bg-[#232323] outline-none text-[13px] font-spline-sans-mono placeholder-[#606060]`}
                            placeholder="Key"
                            value={pair.key}
                            onChange={(e) => onUpdatePair(index, 'key', e.target.value)}
                        />
                        <input 
                            className={`${isLoading ? 'text-[#484848] cursor-not-allowed' : ''} w-[calc(50%-2px)] p-[10px] bg-[#232323] outline-none text-[13px] font-spline-sans-mono placeholder-[#606060]`}
                            placeholder="Value"
                            value={pair.value}
                            onChange={(e) => onUpdatePair(index, 'value', e.target.value)}
                        />
                        {pairs.length && pair.key && pair.value && (
                            <div className="absolute top-[50%] translate-y-[-50%] right-[-25px] w-[23px] h-[23px]">
                                <FiMinus 
                                    className={`${isLoading ? 'text-[#484848] cursor-not-allowed' : 'text-[#FF0000]'} w-full h-full cursor-pointer`} 
                                    onClick={() => onRemovePair(index)}
                                />
                            </div>
                        )}
                    </div>
                    {index === pairs.length - 1 && pair.key && pair.value && (
                        <div className="flex justify-center items-center pt-[14px]">
                            <GoPlus 
                                className={`${isLoading ? 'text-[#484848] cursor-not-allowed' : 'text-[#0099FE]'} w-[33px] h-[33px] cursor-pointer`} 
                                onClick={onAddPair}
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

