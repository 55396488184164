import { useState, useCallback, useEffect } from 'react';
import WebApp from '@twa-dev/sdk'
// Add type declaration for Telegram
declare global {
    interface Window {
        Telegram?: {
            Login: {
                auth: (
                    options: {
                        bot_id: string | undefined;
                        request_access: boolean;
                        embed: boolean;
                    },
                    callback: (data: any) => void
                ) => void;
            };
            WebApp?: any;
        };
    }
}

// Add TelegramUser type
interface TelegramUser {
    id: number;
    first_name: string;
    username?: string;
    photo_url?: string;
}

export function useTelegram() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState<TelegramUser | null>(null);
    const tg = window.Telegram?.WebApp;
    // Initialize from WebApp data
    useEffect(() => {
        try {
            // Check if we're in Telegram WebApp environment
            if (WebApp.initData) {
                const twaUser = WebApp.initDataUnsafe.user;
                if (twaUser) {
                    const telegramUser: TelegramUser = {
                        id: twaUser.id,
                        first_name: twaUser.first_name,
                        username: twaUser.username,
                        photo_url: twaUser.photo_url
                    };
                    setUser(telegramUser);
                    setIsAuthenticated(true);
                    localStorage.setItem('telegramUser', JSON.stringify(telegramUser));
                    return;
                }
            }

            // Fallback to localStorage
            const storedUser = localStorage.getItem('telegramUser');
            if (storedUser) {
                setUser(JSON.parse(storedUser));
                setIsAuthenticated(true);
            }
        } catch (error) {
            console.error('Error initializing Telegram user:', error);
        }
    }, []);

    const login = useCallback(() => {
        if (!window.Telegram) {
            console.error('Telegram SDK not loaded');
            return;
        }
        console.log('bot_id')
        window.Telegram.Login.auth(
            {
                bot_id: process.env.REACT_APP_TELEGRAM_BOT_ID,
                request_access: true,
                embed: false
            },
            (data: TelegramUser) => {
                console.log(data, 'data')
                if (data) {
                    setIsAuthenticated(true);
                    setUser(data);
                    localStorage.setItem('telegramUser', JSON.stringify(data));
                }
            }
        );
    }, []);

    const logout = useCallback(() => {
        setIsAuthenticated(false);
        setUser(null);
        localStorage.removeItem('telegramUser');
    }, []);

    return { isAuthenticated, login, logout, user };
}