import React from "react";
import { OwnerNftData } from "@/types";
import { useEffect, useState } from "react";
import { fetchData, CollectionDataCache } from "@/types/collection";
import { backend } from "@/services/backend";
interface NFTMetadata {
    onClick?: () => void;
    name: string;
    description: string;
    image: string;
}

interface OwnerNFTCardProps {
    className?: string;
    item: OwnerNftData;
    onClick?: () => void;
}


const OwnerNFTCard = ({ className, item, onClick }: OwnerNFTCardProps) => {
    const [metadata, setMetadata] = useState<NFTMetadata | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchNFTData = async () => {
            setIsLoading(true);
            try {
                const metadataData = await fetchData(item.data_url);    
                setMetadata(metadataData);
            } catch (error) {
                console.error('Error fetching NFT data:', error);
                // Optionally set some error state here if you want to show error UI
            } finally {
                setIsLoading(false);
            }
        };

        fetchNFTData();
    }, [item]);


    return (
        <div className={`${className} flex flex-col items-center justify-center`} onClick={onClick}>
            {isLoading ? (
                <div className="w-full aspect-square flex items-center justify-center">
                    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-[#0099FE]"></div>
                </div>
            ) : (
                <>
                    <img src={metadata?.image || ''} className="w-full aspect-square" />
                    <div className="flex flex-col w-full">
                        <div className="flex justify-between">
                            {metadata?.name && metadata.name.length > 7 ? (
                                <>
                                    <p className="text-[12px] w-[calc(100%-10px)] truncate">{metadata.name.slice(0, -3)}</p>
                                    <p className="text-[12px]">{metadata.name.slice(-3)}</p>
                                </>
                            ) : (
                                <p className="text-[12px]">{metadata?.name}</p>
                            )}
                        </div>
                        <p className="text-[12px]">#{item.index_in_collection}</p>
                    </div>
                </>
            )}
        </div>
    )
}

export default OwnerNFTCard;