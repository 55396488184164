import * as React from "react";
import { useState } from "react";
interface SelectInputProps {
  value: boolean;
  onChange: (value: boolean) => void;
  onHelpClick: () => void;
  label: string;
  essential: boolean;
}

export default function SelectInput({ value, onChange , label, essential, onHelpClick }: SelectInputProps) {
  return (
    <div className="w-full flex flex-col items-center">
        <div className="w-full relative text-[24px] text-center pb-[10px]">
            {essential && <span className="text-[#FF0000]">*</span>}
            {label}
            <div className="absolute right-0 top-0">
                <span className="text-[#0099FE]" onClick={onHelpClick}>?</span>
            </div>
        </div>
        <div className="flex w-full text-center">
            <div
            className={`w-[50%] cursor-pointer px-4 py-2 ${
            value === true ? "bg-blue-500 text-white" : "bg-[#232323]"
            }`}
            onClick={() =>{ onChange(!value); }}
             >
                Yes
            </div>
            <div
                className={`cursor-pointer px-4 py-2 w-[50%] ${
                value === false ? "bg-blue-500 text-white" : "bg-[#232323]"
                }`}
                onClick={() =>{onChange(!value); }}
            >
                No
            </div>
        </div>
      
    </div>
  );
}