import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./scss/app.scss";
import "./scss/app.scss";
import { CollectionProvider } from "@/context/CollectionContext";
import CollectionCreatePage from "./pages/createCollection";
import SingleCollectionPage from "./pages/collection";
import MainPage from "./pages/mainPage";
import AccountPage from "./pages/account";
import SingleItemPage from "./pages/item";
import ConfirmWalletPage from "./pages/confirmWallet";
import { masterTonContract } from "./config";
import CreateCollectionPage from "./pages/create-collection";
import CreateCollectionStepPage from "./pages/create-collection-step";
import NFTSMintPage from "./pages/mint";
import OwnerPage from "./pages/owner";
import NftPage from "./pages/nft";
import { ToastContainer } from "react-toastify";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { WalletProvider } from "@/context/WalletContext";
import WebApp from "@twa-dev/sdk";
import { getUrlFromMiniAppParam } from "./utils/telegram";

const App = () => {
  useEffect(() => {
    alert(`Current Version: 1.11.18`);
    const startParam = WebApp.initDataUnsafe.start_param;
    if (startParam) {
      const path = `/${getUrlFromMiniAppParam(startParam)}`;
      const currentPath = `${document.location.pathname}${document.location.search}`;
      alert(`${path}, ${document.location.pathname}, ${path === document.location.pathname}`)
      if (path !== currentPath) {
        document.location.pathname = `${path}`;
      }
    }
  }, []);

  return (
    <BrowserRouter>
      <SnackbarProvider anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <WalletProvider>
          <CollectionProvider>
            <Routes>
              <Route
                path="/collection/create"
                element={<CreateCollectionPage />}
              />
              <Route path="/owner/:address" element={<OwnerPage />} />
              <Route
                path="/collection/create/:step"
                element={<CreateCollectionStepPage />}
              />
              <Route
                path="/collection/:collection"
                element={<NFTSMintPage />}
              />
              <Route path="/nft/:id" element={<NftPage />} />
              <Route path="/walletconfirm" element={<ConfirmWalletPage />} />
              {/* <Route path="/owner/:address" element={<AccountPage />}/> */}
              {/* <Route path="/collection/create" element={<CollectionCreatePage />}/> */}
              {/* <Route path="/collection/:collection" element={<SingleCollectionPage />} /> */}
              <Route path="/item/:item" element={<SingleItemPage />} />
              <Route path="/" element={<CreateCollectionPage />} />
            </Routes>
          </CollectionProvider>
        </WalletProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
};

export default App;
