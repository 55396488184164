import * as React from "react";
import Button from "./button";

interface NftErrorMessageProps {
    avatar: string;
    index: number; 
    className?: string;
    collectionName: string;
    errorMessage: string;
    onClose: () => void;
    onClick: () => void;
}

export default function NftErrorMessage({className, avatar, index, collectionName, errorMessage, onClose, onClick}: NftErrorMessageProps) {
    return (
        <div className={`${className} `}>
            <div className="relative">
                <header className="relative flex justify-center items-center pl-[20px] pr-[20px] pt-[12px] pb-[12px] bg-[#FE0000] ">
                    <div className="text-[33px] text-[black]">
                        <span >Error!</span>     
                    </div>
                    <div className="absolute right-[20px] w-[29px] h-[29px] hover:cursor-pointer" onClick={onClose}>
                        <img src="/assets/black-cancel.svg" alt="cancel" />
                    </div>
                </header>
                <main className="flex flex-col justify-center items-center pt-[58px] pb-[30px] pl-[54px] pr-[54px] text-[24px]">
                    <div className="w-[145px] aspect-square relative">
                        <img src={avatar} alt="avatar" />
                        <div className="absolute top-0 left-0 w-[145px] aspect-square  flex justify-center items-center">
                            <img src="/assets/avatar-bg.png" alt="index" />
                        </div>
                    </div>
                    <p className="pt-[28px] text-red-500 font-spline-sans-mono">
                        {errorMessage}
                    </p>
                    <div className="pt-[50px] w-full">
                        <Button 
                            className="w-full custom-aspect-320-63 text-[24px] hover:cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out" 
                            image="/assets/button.svg" 
                            text="OK" 
                            onClick={() => {onClick()}}
                        />
                    </div>
                </main>

            </div>
        </div>
    )
}