import * as React from "react";
import Button from "./button";

interface CollectionErrorMessageProps {
    className?: string;
    errorMessage: string;
    onClose: () => void;
    onClick: () => void;
}

export default function CollectionErrorMessage({className, errorMessage, onClose, onClick}: CollectionErrorMessageProps) {
    return (
        <div className={`${className} `}>
            <div className="relative min-h-[541px]">
                <header className="relative flex justify-center items-center pl-[20px] pr-[20px] pt-[12px] pb-[12px] bg-[#FF0000] ">
                    <div className="text-[33px]">
                        <span className="text-black">ERROR!</span>     
                    </div>
                    <div className="absolute right-[20px] w-[29px] h-[29px] hover:cursor-pointer" onClick={onClose}>
                        <img src="/assets/black-cancel.svg" alt="cancel" />
                    </div>
                </header>
                <main className="pt-[28px] pb-[20px] pl-[54px] pr-[54px] text-[24px]">
                   <p className="text-[#FF0000] font-spline-sans-mono">{errorMessage}</p>
                    <div className="pt-[20px] w-full">
                        <Button 
                            className="w-full custom-aspect-320-63 text-[24px] hover:cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out" 
                            image="/assets/button.svg" 
                            text="OK" 
                            onClick={() => {onClick()}}
                        />
                    </div>
                </main>

            </div>
        </div>
    )
}