import * as React from "react";

export default function StepLoad({currentIndex, totalIndex}: {currentIndex: number, totalIndex: number}) {
    return (
        <div className="flex items-center justify-center gap-[10px]">
            {Array.from({ length: totalIndex }).map((_, index) => (
                <div key={index} className={`w-[9px] h-[9px]  ${index === currentIndex ? 'bg-white' : 'bg-[#484848]'}`}></div>
            ))}
        </div> 
    );
}