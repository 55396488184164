import * as React from "react";

export default function CreateCollectionStepHeader({step}: {step: number}) {
    return (
        <header className="relative w-full h-[134px]">
            <img src="/background.png" alt="step-1" />
            <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-[40px] hover:cursor-pointer flex flex-col items-center justify-center">
                <span className="line-height-1">COLLECTION</span>
                <span className="line-height-1">CREATING</span>
            </div>
        </header>
    )
}