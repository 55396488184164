import { NFT } from "@/types/collectionType";

export const nft: NFT = {
    id: "#1515",             
    name: "PUNK MONSTERS",         
    description: "What are you up to on this hallow night?What are you up to on this hallow night?What are you up to on this hallow night?What are you up to on this hallow night?What are you up to on this hallow night?What are you up to on this hallow night?",
    collectionName: "Punk Monsters",
    image: "/assets/avatar.png",             
    owner: "OGDREe1UU#78vR34m2gIL3l",        
    contractAddress: "OGDREe1UU#78vR34m2gIL3l",
    metaData: [
        {
            key: "Level",
            value: "75"
        },
        {
            key: "Range",
            value: "125"
        },
        {
            key: "Trololo",
            value: "561"
        }
    ]
}


export const nftData = [
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        id: "#6586",
        ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
        price: 10,
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        id: "#6586",
        ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
        price: 10,
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        id: "#6586",
        ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
        price: 10,
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        id: "#6586",
        ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
        price: 10,
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        id: "#6586",
        ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
        price: 10,
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        id: "#6586",
        ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
        price: 10,
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        id: "#6586",
        ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
        price: 10,
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        id: "#6586",
        ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
        price: 10,
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        id: "#6586",
        ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
        price: 10,
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        id: "#6586",
        ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
        price: 10,
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        id: "#6586",
        ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
        price: 10,
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        id: "#6586",
        ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
        price: 10,
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        id: "#6586",
        ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
        price: 10,
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        id: "#6586",
        ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
        price: 10,
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        id: "#6586",
        ownerAddress: "OGDREe1UU#78vR34m2gIL3l",
        price: 10,
    }
   
]