import * as React from "react";
import ContractCard from "@/components/atomics/contract-card";
import Direction from "@/components/atomics/direction";
import OwnerView from "@/components/atomics/owner-view";
import { useNavigate } from "react-router-dom";
import OwnerAvatar from "@/components/molecules/owner/owner-avatar";
import StatCard from "@/components/atomics/stat-card";
import { exampleOwnerData } from "@/data/owner";
import TextArea from "@/components/atomics/text-area";
import Social from "@/components/molecules/owner/social";
import ImageUploadButton from "@/components/atomics/image-upload-button";
import EditView from "@/components/atomics/edit-view";
import { useState, useMemo } from "react";
import { OwnerData } from "@/types";
import { useWallet } from "@/context/WalletContext";
import TonWeb from "tonweb";
import { useTelegram } from "@/hooks/useTelegram";

interface OwnerProps  {
  className?: string;
  ownerDatas: OwnerData | null;
}


export default function OwnerEditView({ className, ownerDatas }: OwnerProps) {
  const navigate = useNavigate();
  const { wallet, disconnect } = useWallet();
  const [isOwnerView, setIsOwnerView] = React.useState(true);
  const [isEditView, setIsEditView] = React.useState(false);
  const [ownerData, setOwnerData] = useState(exampleOwnerData);
  const [socialValues, setSocialValues] = useState(exampleOwnerData.socials.map(s => s.url));
  const { isAuthenticated, user } = useTelegram();
  const tg = window.Telegram?.WebApp;

  const stats = useMemo(() => ({
    created_collections: ownerDatas?.collections?.length || 0,
    minted_nfts: ownerDatas?.collections?.reduce((sum, collection) => sum + collection.next_item_index , 0) || 0,
    earned: Number((ownerDatas?.collections?.reduce((sum, collection) => 
      sum + (collection.next_item_index * collection.price/1000000000), 0) || 0).toFixed(2)),
  }), [ownerDatas]);

  const handleViewToggle = () => {
    setIsOwnerView(!isOwnerView);
  }

  const showEdit = () => {
    setIsEditView(true);
  }

  const handleEdit = () => {
    setIsEditView(false);
  };

  const handleCancel = () => {
    setOwnerData(exampleOwnerData);
    setSocialValues(exampleOwnerData.socials.map(s => s.url));
    setIsEditView(false);
  };

    const updateBackground = (file) => {
        if (file) {
            setOwnerData(prev => ({
                ...prev,
                background: URL.createObjectURL(file)
            }));
        }
    }

    const updateAvatar = (file) => {
        if (file) {
            setOwnerData(prev => ({
                ...prev,
                avatar: URL.createObjectURL(file)
            }));
        }
    }

    const updateInfo = (e) => {
        setOwnerData(prev => ({
            ...prev,
            info: e.target.value
        }));
    }
    const updateState = (index) => {
      console.log(index, "index")
      setOwnerData(prev => ({
        ...prev,
        stats: prev.stats.map((s, i) => 
          i === index ? { ...s, isVisible: !s.isVisible } : s
        )
      }));
    }

  const handleSocialChange = (index: number, value: string) => {
    // Update the socialValues state
    setSocialValues(prev => {
      const newValues = [...prev];
      newValues[index] = value;
      return newValues;
    });

    // Update the ownerData state with new social URL
    setOwnerData(prev => ({
      ...prev,
      socials: prev.socials.map((social, i) => 
        i === index ? { ...social, url: value } : social
      )
    }));
  };

  const truncateAddress = (address: string): string => {
    if (!address) return '';
    try {
        // Convert raw address to user-friendly format
        const rawAddress = new TonWeb.utils.Address(address);
        const userFriendlyAddress = rawAddress.toString(true, true, true);
        return userFriendlyAddress;
    } catch (e) {
        return address;
    }
};

  // Update initial owner data with Telegram info if authenticated
  React.useEffect(() => {
    if (isAuthenticated && user) {
      setOwnerData(prev => ({
        ...prev,
        name: user.username || prev.name,
        avatar: user.photo_url || prev.avatar
      }));
    }
  }, [isAuthenticated, user]);

  const handleShare = () => {
    const currentUrl = window.location.href;
    
    if (tg && tg.WebApp) {
      console.log(tg.WebApp, 'tg.WebApp')
      tg.WebApp.switchInlineQuery(currentUrl, ['users', 'groups']);
    } else {
      const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(currentUrl)}`;
      window.open(telegramShareUrl, '_blank');
    }
  };

  return (
    <div className={`w-full relative`}>
      {!isEditView && (
        <OwnerView 
          isOwnerView={isOwnerView}
          handleViewToggle={handleViewToggle}
        />
      )}
      {isEditView && (
        <EditView 
          className="fixed top-[90px] left-[50%] translate-x-[-50%] z-[1] bg-black"
          handleEdit={handleEdit}
          handleCancel={handleCancel}
        />
      )}
      <div className="relative w-full h-[180px]">
        <img src={ownerData.background} className={`${isEditView ? 'opacity-40' : ''}`} alt="background" />
        {isEditView && (
            <ImageUploadButton 
                className="absolute top-[24px] right-[24px]"
                maxResolution={{width: 2880,height: 640}}
                maxFileSize={5}
                onFileSelect={(file) => {
                    updateBackground(file);
                }}
            />
        )}
        {isOwnerView && (
          <div className={`${isEditView ? 'opacity-40' : ''} absolute bottom-[-49px] left-[28px] z-[1] hover:cursor-pointer`} onClick={showEdit}>
            <img src="/assets/edit.svg"/>
          </div>
        )}
        <div 
        className={`${isEditView ? 'opacity-40' : ''} absolute bottom-[-49px] right-[28px] z-[1] hover:cursor-pointer`} 
          onClick={handleShare}>
            <img src="/assets/share.svg"/>
        </div>
        <div className="w-full absolute left-[50%] translate-x-[-50%] translate-y-[-80px] flex flex-col items-center justify-center">
          <div className="relative">
            <OwnerAvatar 
                className={`${isEditView ? 'opacity-40' : ''} w-[143px] rounded-full`}
                image={user?.photo_url || ''} 
            />
            {isEditView && (
                <ImageUploadButton 
                    className="absolute z-[10] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                maxResolution={{width: 1024, height: 1024}}
                maxFileSize={2}
                onFileSelect={(file) => {
                    updateAvatar(file);
                }}
                />
            )}
            </div>  
          
          <div className={`${isEditView ? 'opacity-40' : ''} text-[32px]`}>
            <span>{user?.username || ''}</span>
          </div>
          <ContractCard 
            className={`${isEditView ? 'opacity-40' : ''} pt-[10px] pb-[10px]`}  
            length={7}
            text="WALLET" 
            address={truncateAddress(wallet ? (typeof wallet === 'string' ? wallet : wallet.account.address) : '')} />
        </div>
      </div>

      <div className="px-[30px] pt-[193px]">
        <div className="flex justify-center gap-[10px] pb-[50px]">
          <StatCard 
            key="collections"
            title="Created"
            subtitle="COLLECTIONS"
            value={stats.created_collections}
            isVisible={ownerData.stats[0].isVisible}
            isOwnerView={isOwnerView}
            isEditView={isEditView}
            onChange={() => updateState(0)}
          />
          <StatCard 
            key="nfts"
            title="Minted"
            subtitle="NFTs"
            value={stats.minted_nfts}
            isVisible={ownerData.stats[1].isVisible}
            isOwnerView={isOwnerView}
            isEditView={isEditView}
            onChange={() => updateState(1)}
          />
          <StatCard 
            key="earned"
            title="Earned"
            subtitle="TON"
            value={stats.earned}
            isVisible={ownerData.stats[2].isVisible}
            isOwnerView={isOwnerView}
            isEditView={isEditView}
            onChange={() => updateState(2)}
          />
        </div>

        <div className="flex flex-col">
          <span className="text-[24px]">INFO:</span>
          <TextArea 
            className="h-[173px]"
            value={ownerData.info}
            totalTextCount={100}
            notEditable={!isEditView}
            onChange={(e) => {
                updateInfo(e)
            }}
          />
        </div>
        {/* <div className="pt-[20px] pb-[23px] flex pr-[1px] items-end justify-end">
          <div className="right-[10px] flex items-center justify-center gap-[10px]">
            <span className="text-[#0099FE] text-[24px]">MORE</span>
            <Direction color="#0099FE" className="w-[11px] h-[19px] rotate-[90deg]"/>
          </div>
        </div> */}
        <div className={`flex ${isEditView ? 'flex-col gap-[10px]' : 'flex-row gap-[20px]'} py-[30px]`}>
           {ownerData.socials.map((social, index) => (
            <Social 
              key={index} 
              {...{ [social.type]: social.url }}
              value={social.url}
              onChange={(value) => handleSocialChange(index, value)}
              isEditView={isEditView}
            />
           ))}
        </div>
      </div>
    </div>
  );
} 