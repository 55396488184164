import * as React from "react";
import Button from "./button";

interface CollectionSuccessMessageProps {
    className?: string;
    collectionName: string;
    onClose: () => void;
    onClick: () => void;
}

export default function CollectionSuccessMessage({className, collectionName, onClose, onClick}: CollectionSuccessMessageProps) {
    return (
        <div className={`${className} `}>
            <div className="relative min-h-[541px]">
                <header className="relative flex justify-center items-center pl-[20px] pr-[20px] pt-[12px] pb-[12px] bg-[#0099FE] ">
                    <div className="text-[28px] text-center">
                        <span >CONGRATULATION!</span>     
                    </div>
                    <div className="absolute right-[20px] w-[21px] h-[29px] hover:cursor-pointer" onClick={onClose}>
                        <img src="/assets/cancel.svg" alt="cancel" />
                    </div>
                </header>
                <main className="pt-[28px] pb-[20px] pl-[54px] pr-[54px] text-[24px] ">
                   <div className="font-spline-sans-mono">
                    <p>YOUR COLLECTION:</p>
                    <p className="text-[24px] font-spline-sans-mono">
                        "<span className="text-[#0099FE]">{collectionName}</span>"
                    </p>
                    <p>successfully created!</p>
                    <p className="pt-[20px]">Go to the collection page to complete customization</p>
                   </div>
                    <div className="pt-[40px] w-full">
                        <Button 
                            className="w-full custom-aspect-320-63 text-[24px] hover:cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out" 
                            image="/assets/button.svg" 
                            text="GO TO COLLECTION" 
                            onClick={() => {onClick()}}
                        />
                    </div>
                </main>

            </div>
        </div>
    )
}