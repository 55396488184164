import * as React from "react";
import Header from "@/components/template/dashboard-layout/header";
import Footer from "@/components/template/dashboard-layout/footer";
import CreateCollection from "@/components/organisms/create-collection";

export default function CreateCollectionPage() {
    return (
        <>
            <Header />
                <CreateCollection />
            <Footer />
        </>
    )
}