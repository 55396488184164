import * as React from "react";
import Button from "./button";

interface HelpProps {
    className?: string;
    title: string;
    description: string;
    onClose: () => void;
    onOk: () => void;
}

export default function Help({className, title, description, onClose, onOk}: HelpProps) {
    return (
        <div className={`${className} `}>
            <div className="relative min-h-[541px]">
                <header className="flex justify-between items-center pl-[20px] pr-[20px] pt-[12px] pb-[12px] bg-[#0099FE] ">
                    <div className="text-[33px]">
                        <span >INFORMATION</span>     
                    </div>
                    <div className="w-[29px] h-[29px] hover:cursor-pointer" onClick={onClose}>
                        <img src="/assets/cancel.svg" alt="cancel" />
                    </div>
                </header>
                <main className="pt-[28px] pb-[120px] pl-[54px] pr-[54px]">
                    <h1 className="text-[28px] text-[#0099FE] font-spline-sans-mono">
                        {title}
                    </h1>
                    <p className="pt-[10px] pb-[20px] text-[21px] font-spline-sans-mono">
                    {description}
                    </p>
                </main>
                <div className="absolute bottom-[50px] pl-[54px] pr-[54px] w-full">
                    <Button 
                        className="w-full custom-aspect-320-63 text-[24px] hover:cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out" 
                        image="/assets/button.svg" 
                        text="OK" 
                        onClick={() => {onOk()}}
                    />
                </div>

            </div>
        </div>
    )
}