import * as React from "react";
import Header from "@/components/template/dashboard-layout/header";
import Footer from "@/components/template/dashboard-layout/footer";
import Owner from "@/components/organisms/owner";
import NFT from "@/components/organisms/nft";
import { nft } from "@/data/nft-data";
import { useParams } from "react-router-dom";
import { ItemDataType, ItemFileData,  CollectionDataCache } from "@/types";
import { useState, useEffect } from "react";
import { backend } from "@/services/backend";
export default function NftPage() {

    const { id } = useParams();
    const [itemInfo, setItemInfo] = useState<ItemDataType | null>(null);
    const [nftContent, setNftContent] = useState<ItemFileData | null>(null);
    const [collectionData, setCollectionData] = useState<CollectionDataCache | null>(null);

    useEffect(() => {
        let isMounted = true;
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const fetchData = async () => {
            if (!id) return;
            
            try {
                const data = await backend.getItemData(id);
                if (!isMounted) return;
                console.log(data, 'data');
                setItemInfo(data);
                if (data?.content) {
                    const response = await fetch(data.content);
                    const contentData: ItemFileData = await response.json();
                    if (!isMounted) return;
                    
                    setNftContent(contentData);
                }

                if (data?.collection_address) {
                    const collectionData = await backend.getCollectionData(data.collection_address);
                    if (!isMounted) return;
                    setCollectionData(collectionData);
                }

            } catch (err) {
                if (isMounted) {
                    alert(err);
                }
            }
        };

        fetchData();
        
        return () => {
            isMounted = false;
        };
    }, [id]);

    console.log(itemInfo, nftContent, "itemInfo, nftContent");

    return (
        <>
            <Header />
                <NFT 
                    nftData={nft} 
                    itemInfo={itemInfo || null} 
                    nftContent={nftContent || null}
                    collectionData={collectionData} 
                />
            <Footer />
        </>
    )
}