import React, { createContext, useContext, useState, useCallback } from 'react';

interface IpfsUrls {
    cover: string | null;
    icon: string | null;
    nft: string | null;
}

interface FormData {
    [key: string]: string | boolean;
}

interface CollectionContextType {
    ipfsUrls: IpfsUrls;
    setIpfsUrls: (urls: IpfsUrls) => void;
    formData: FormData;
    setFormData: (data: FormData) => void;
}

const CollectionContext = createContext<CollectionContextType | undefined>(undefined);

export const CollectionProvider = ({ children }) => {
    const [ipfsUrls, setIpfsUrls] = useState({
        cover: null,
        icon: null,
        nft: null
    });
    const [formData, setFormData] = useState({});

    const updateIpfsUrls = useCallback((urls) => {
        setIpfsUrls(urls);
    }, []);

    const updateFormData = useCallback((data) => {
        setFormData(prevData => ({
            ...prevData,
            ...data
        }));
    }, []);

    const value = {
        ipfsUrls,
        formData,
        setIpfsUrls: updateIpfsUrls,
        setFormData: updateFormData
    };

    return (
        <CollectionContext.Provider value={value}>
            {children}
        </CollectionContext.Provider>
    );
};

export function useCollection() {
    const context = useContext(CollectionContext);
    if (undefined === context) {
        throw new Error('useCollection must be used within a CollectionProvider');
    }
    return context;
}