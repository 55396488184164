import * as React from "react";
import OwnerCollection from "./owner-collection";
import OwnerEditView from "./owner-edit-view";
import OwnerNft from "./owner-nft";
import { exampleOwnerData } from "@/data/owner";
import { OwnerData } from "@/types";

export default function Owner ({ ownerData }: { ownerData: OwnerData | null }) {

  return (
    <>
      <OwnerEditView ownerDatas={ownerData} />
      <OwnerCollection 
        collectionData = {ownerData?.collections || []}
      />
      <OwnerNft 
        itemsData = {ownerData?.items || []}
      />
    </>
  )
}