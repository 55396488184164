import * as React from "react";
import SearchInput from "../../atomics/search-input";
import { HiOutlineBars2 } from "react-icons/hi2";
import { HiXMark } from "react-icons/hi2";
import { useState } from 'react';
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";
import { TonConnectButton, useTonConnectUI } from "@tonconnect/ui-react";
import TonWeb from 'tonweb';
import { useTelegram } from "@/hooks/useTelegram";
import { useSnackbar } from "notistack";
import { useWallet } from '@/context/WalletContext';
import { useTonConnect } from "@/hooks/useTonConnect";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from 'notistack';
import WebApp from '@twa-dev/sdk';

export default function Header() {

    const { isMenuOpen, toggleMenu } = useWallet();
    const tonButtonRef = React.useRef<HTMLDivElement>(null);
    const { wallet, disconnect } = useWallet();

    const { isAuthenticated, login: telegramLogin, logout: telegramLogout, user } = useTelegram();

    const truncateAddress = (address: string): string => {
        if (!address) return '';
        try {
            // Convert raw address to user-friendly format
            const rawAddress = new TonWeb.utils.Address(address);
            const userFriendlyAddress = rawAddress.toString(true, true, true);
            return `${userFriendlyAddress.slice(0, 8)}...${userFriendlyAddress.slice(-4)}`;
        } catch (e) {
            return `${address.slice(0, 6)}...${address.slice(-4)}`;
        }
    };

    const handleConnectWallet = () => {
        tonButtonRef.current?.querySelector('button')?.click();
    };

    const handleLogoClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleDisconnect = () => {
        disconnect();
    };

    const navigate = useNavigate();

    const handleMyCollections = () => {
        if (!wallet) {
            enqueueSnackbar('Please connect your wallet first', { variant: 'warning' });
            return;
        }
        toggleMenu();
        const address = typeof wallet === 'string' ? wallet : wallet.account.address;
        const rawAddress = new TonWeb.utils.Address(address);
        const userFriendlyAddress = rawAddress.toString(true, true, true);
        navigate(`/owner/${userFriendlyAddress}`);
    };

    return (
        <>
            <header className='sticky z-[20] top-0 w-full flex items-center justify-between pt-[20px] pl-[10px] pr-[10px] pb-[10px] sm:p-[20px] gap-[7px] sm:gap-[10px] sl:gap-[20px] bg-black'>
                <img  
                    src="/logo.svg"
                    alt="logo" 
                    className='hover:cursor-pointer w-[51px] h-[51px]'
                    onClick={handleLogoClick} 
                />
                <SearchInput 
                    className='w-full'
                    placeholder='Search' 
                />
                <button 
                    onClick={toggleMenu}
                    className="z-20"
                >
                    {isMenuOpen ? (
                        <HiXMark className='text-[#4E4E4E] w-[51px] h-[31px] hover:cursor-pointer transition-all duration-300' />
                    ) : (
                        <HiOutlineBars2 className='text-[#4E4E4E] w-[51px] h-[31px] hover:cursor-pointer transition-all duration-300' />
                    )}
                </button>
            
                {isMenuOpen && (
                    <div
                        className="fixed top-0 right-0 h-screen w-full bg-black shadow-lg z-10"
                    >
                        <div className="absolute top-[20px] left-[10px] sm:left-[20px]">
                            <img  
                                src="/logo.svg"
                                alt="logo" 
                                className='w-[51px] h-[51px]'
                            />
                        </div>
                        <div className='relative h-full'>
                            <div className="mt-[76px] border-[#1E1E1E] border-t-[1px] pt-[16px] pl-[20px] pr-[30px] sl:pl-[20px] flex flex-col gap-[20px]">
                                <div className='hover:cursor-pointer font-silkscreen text-[25px] sl:text-[30px]     '
                                    onClick={() => {
                                        toggleMenu();
                                        navigate('/')
                                    }}
                                >
                                    <span>HOME</span>
                                </div>
                                <div >
                                    {wallet ? (
                                        <div className='font-silkscreen text-[25px] sl:text-[30px] flex justify-between items-center gap-[15px]'
                                             onClick={handleDisconnect}>
                                            <div className="flex items-center gap-[15px]">
                                                <img 
                                                    src="/assets/connected-wallet.svg" 
                                                    className="w-[33px] h-[30px]"
                                                />
                                                <span className="text-[#0099FE] text-[20px] font-spline-sans-mono">{typeof wallet === 'string' ? truncateAddress(wallet) : truncateAddress(wallet.account.address)}</span>
                                            </div>
                                            <div
                                              
                                                className="hover:cursor-pointer"
                                            >
                                                <img 
                                                    src="/assets/red-cancel.svg" 
                                                    className="w-[25px] h-[25px]"
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='font-silkscreen text-[25px] sl:text-[30px] flex justify-between items-center gap-[15px]'
                                             onClick={handleConnectWallet}
                                        >
                                            <p>CONNECT WALLET</p>
                                            <div>
                                                <img 
                                                    src="/assets/connect-wallet.svg" 
                                                    className="w-[33px] h-[30px]"
                                                   
                                                />
                                                <div ref={tonButtonRef} className="absolute opacity-0 pointer-events-none">
                                                    <TonConnectButton />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div 
                                    className='hover:cursor-pointer font-silkscreen text-[25px] sl:text-[30px]'
                                    onClick={handleMyCollections}
                                >
                                    <span>MY COLLECTIONS</span>
                                </div>
                                <div 
                                    onClick={() => {
                                        toggleMenu();
                                        navigate('/collection/create')
                                    }}
                                    className='hover:cursor-pointer font-silkscreen text-[25px] sl:text-[30px]'
                                >
                                    <span>CREATE COLLECTION</span>
                                </div>
                                <div className='font-silkscreen text-[25px] sl:text-[30px] flex justify-between items-center gap-[15px]'>
                                    {isAuthenticated ? (
                                        <div className="flex items-center gap-[15px]">
                                            <FaTelegramPlane className='text-[#0099FE] w-[30px] h-[30px]' />
                                            <span className="text-[#0099FE] text-[25px] font-spline-sans-mono">
                                                { user?.username || 'Connected'}
                                            </span>
                                            {/* <div onClick={telegramLogout} className="hover:cursor-pointer">
                                                <img 
                                                    src="/assets/red-cancel.svg" 
                                                    className="w-[25px] h-[25px]"
                                                />
                                            </div> */}
                                        </div>
                                    ) : !WebApp.initData ? (
                                        <div className="w-full flex items-center justify-between text-[25px] sl:text-[30px] ">
                                            <span>VERIFY TELEGRAM</span>
                                            <FaTelegramPlane 
                                                className='text-white w-[25px] h-[25px] hover:cursor-pointer' 
                                                onClick={telegramLogin}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                                <div className='hover:cursor-pointer font-silkscreen text-[25px] sl:text-[30px]'>
                                    <span>DOMAINS</span>
                                </div>
                                <div className='hover:cursor-pointer font-silkscreen text-[25px] sl:text-[30px]'>
                                    <span>SETTINGS</span>
                                </div>
                            </div>
                            <div className='absolute bottom-[120px] w-full flex items-center justify-center gap-[26px]'>
                                <FaTelegramPlane className='text-white w-[33px] h-[28px]' />
                                <FaXTwitter className='text-white w-[28px] h-[27px]' />
                                <FaDiscord className='text-white w-[39px] h-[28px]' />
                            </div>
                        </div>
                    </div>
                )}
            </header>    
        </>
    )
}

