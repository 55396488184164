import * as React from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoIosRocket } from "react-icons/io";
import { MdOutlineSpa } from "react-icons/md";

export default function Social({ 
  telegram, 
  twitter, 
  rocket, 
  spa,
  value,
  onChange,
  isEditView
}: {
  telegram?: string;
  twitter?: string;
  rocket?: string;
  spa?: string;
  value: string;
  onChange: (value: string) => void;
  isEditView: boolean;
}) {
    const socialLinks = [
        { icon: FaTelegramPlane, link: telegram, label: 'Telegram' },
        { icon: FaXTwitter, link: twitter, label: 'Twitter' },
        { icon: IoIosRocket, link: rocket, label: 'Rocket' },
        { icon: MdOutlineSpa, link: spa, label: 'Spa' },
    ];

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    };

    return (
        <div className=" flex justify-between items-center gap-[10px]">
            {socialLinks
                .filter(({ link }) => link)
                .map(({ icon: Icon, link, label }) => (
                    isEditView ? (
                        <Icon key={label} className="w-[29px] h-[24px] text-white" aria-label={label} />
                    ) : (
                        <a 
                            key={label} 
                            href={link} 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <Icon className="w-[29px] h-[24px] text-white hover:text-[#0099FE] transition-colors" aria-label={label} />
                        </a>
                    )
                ))}
            {isEditView && (
                <input  
                    className="w-full h-[31px] p-[5px] bg-[#232323] outline-none text-[16px] font-spline-sans-mono placeholder-[#606060]" 
                    value={value}
                    onChange={handleInputChange}
                />
            )}
        </div>
    );
}


