import * as React from "react";
import { useState } from "react";

interface LoadingProps {
    isLoading: boolean;
}

export default function Loading({ isLoading }: LoadingProps) {
    const [loadingDots, setLoadingDots] = useState(0);
    React.useEffect(() => {
        let interval: NodeJS.Timeout;
        if (isLoading) {
            interval = setInterval(() => {
                setLoadingDots(prev => (prev + 1) % 3);
            }, 300);
        }
        return () => clearInterval(interval);
    }, [isLoading]);
    
    return (
        <>
         {isLoading && (
                <div className="flex gap-[10px] mt-4">
                    <div className={`w-[9px] h-[9px]   transition-opacity duration-300 ${loadingDots === 0 ? 'bg-white' : 'bg-[#0099FE]'}`} />
                    <div className={`w-[9px] h-[9px]  transition-opacity duration-300 ${loadingDots === 1 ? 'bg-white' : 'bg-[#0099FE]'}`} />
                    <div className={`w-[9px] h-[9px]  transition-opacity duration-300 ${loadingDots === 2 ? 'bg-white' : 'bg-[#0099FE]'}`} />
                </div>
            )}
        </>
    )
}
