import React, { createContext, useContext, useState, useEffect } from 'react';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useSnackbar } from 'notistack';
import { useTonConnect } from "@/hooks/useTonConnect";
interface Wallet {
    account: {
        address: string;
    };
    disconnect: () => Promise<void>;
}

interface WalletContextType {
    wallet: Wallet | null;
    isConnected: boolean;
    disconnect: () => Promise<void>;
    isMenuOpen: boolean;
    toggleMenu: () => void;
}

const WalletContext = createContext<WalletContextType | undefined>(undefined);

export function WalletProvider({ children }: { children: React.ReactNode }) {
    const {wallet} = useTonConnect() as { wallet: Wallet | null };
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [tonConnectUI] = useTonConnectUI();
    const { enqueueSnackbar } = useSnackbar();

    const toggleMenu = () => {
        setIsMenuOpen(prev => !prev);
    };

    useEffect(() => {
      
            if (wallet) {
                enqueueSnackbar("Wallet connected successfully!", { variant: "success", autoHideDuration: 1000 });
        }
    }, [wallet]);

    const disconnect = async () => {
        try {
            await tonConnectUI.disconnect();
            enqueueSnackbar("Wallet is disconnected!", {
                variant: "info",
                autoHideDuration: 1000,
            });
        } catch (error) {
            console.error("Failed to disconnect:", error);
        }
    };

    return (
        <WalletContext.Provider value={{ 
            wallet, 
            isConnected: !!wallet,
            disconnect,
            isMenuOpen,
            toggleMenu
        }}>
            {children}
        </WalletContext.Provider>
    );
}

export function useWallet() {
    const context = useContext(WalletContext);
    if (context === undefined) {
        throw new Error('useWallet must be used within a WalletProvider');
    }
    return context;
} 