import * as React from "react";

interface TextAreaType {
    className?: string;
    placeholder?: string;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    label?: string;
    essential?: boolean;
    totalTextCount?: number;
    notEditable?: boolean;
    isLoading?: boolean;
    onHelpClick?: () => void;
  }

export default function TextArea({
    className, 
    placeholder, 
    value, 
    onChange, 
    label, 
    essential, 
    totalTextCount,
    notEditable,
    isLoading,
    onHelpClick
  }: TextAreaType) {
    return (
        <div className={`relative`}>
            <div className={`${isLoading ? 'text-[#484848]' : ''} relative text-[24px] text-center pb-[10px]`}>
                {essential && <span className={`${isLoading ? 'text-[#484848]' : 'text-[#FF0000]'} `}>*</span>}
                {label}
                {label && (
                    <div className="absolute right-0 top-0">
                        <span className={`${isLoading ? 'text-[#484848]' : 'text-[#0099FE]'}`} onClick={onHelpClick}>?</span>
                    </div>
                )}
            </div>
            <textarea  
                className={`${className} w-full h-[200px] p-[10px] ${notEditable ? 'bg-black' : 'bg-[#232323]'} ${isLoading ? 'text-[#484848] cursor-not-allowed' : ''} outline-none text-[13px] font-spline-sans-mono placeholder-[#606060]`}
                placeholder={placeholder} 
                value={value} 
                onChange={onChange}
                readOnly={notEditable}
            />
            {!notEditable && (      
                <div className="absolute bottom-[6px] right-[4px] text-[13px] font-spline-sans-mono">
                    <span className={`${isLoading ? 'text-[#484848]' : ''}`}>{value?.length}/{totalTextCount}</span>
                </div>
            )}
        </div>      
    )
}