import { motion, AnimatePresence } from 'framer-motion';
import * as React from 'react';

interface MotionModalProps {
    isOpen: boolean;
    children: React.ReactNode;
    className?: string;
}

export default function MotionModal({ isOpen, children, className = '' }: MotionModalProps) {
    return (
        <AnimatePresence>
        {isOpen && (
            <motion.div
                initial={{ opacity: 1, y: '100%' }}
                animate={{ opacity: 1, y: '0%' }}
                exit={{ opacity: 1, y: '100%' }}
                transition={{ type: "spring", damping: 25, stiffness: 200 }}
                className={`fixed z-[10] bottom-0 left-0 w-full ${className}`}
            >
                {children}
            </motion.div>
        )}
    </AnimatePresence>
    );
}