import * as React from "react";
import CollectionCard from "@/components/molecules/create-collection/collection-card";
import { useNavigate } from "react-router-dom";
import { CollectionType } from "@/types/collectionType";
import { useEffect, useState } from "react";
interface OwnerCollectionProps {
    collectionData: any[];
}

export default function OwnerCollection({ collectionData }: OwnerCollectionProps) {
    const navigate = useNavigate();
    console.log(collectionData, 'collectionData');
    const [displayCount, setDisplayCount] = React.useState(4);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleLoadMore = () => {
        setIsLoading(true);
        setTimeout(() => {
            if (displayCount >= collectionData.length) {
                setDisplayCount(4);
            } else {
                setDisplayCount(prev => Math.min(prev + 4, collectionData.length));
            }
            setIsLoading(false);
        }, 300);
    };

    return (
        <div className="w-full ">
            <div className="w-full bg-white px-[30px]  pt-[20px] pb-[20px]">
                <div className="pl-[20px] pb-[23px]">
                    <span className="text-[30px] text-black">MY COLLECTIONS</span>
                </div>
                <div className="grid grid-cols-2 gap-[10px] transition-all duration-500 ease-in-out">
                    {collectionData.slice(0, displayCount).map((item, index) => (
                        <CollectionCard 
                            key={index}
                            className={`w-full flex-shrink-0 transition-all duration-300 ease-in-out ${
                                isLoading ? 'animate-pulse opacity-60' : 'opacity-100'
                            }`}
                            onClick={() => navigate(`/collection/${item.address}`)}
                            data_url={item.data_url}
                            value={item.price*item.next_item_index/1000000000}
                            totalMintCount={item.mint_limit}
                            currentMintedCount={item.next_item_index}
                        />
                    ))}
                </div>
                {collectionData.length > displayCount && (
                <div 
                    className={`pt-[17px] flex justify-center flex-col items-center cursor-pointer ${
                        isLoading ? 'animate-bounce' : 'hover:scale-105'
                    } transition-all duration-300`}
                    onClick={handleLoadMore}
                >
                    <div className="w-[19px] h-[19px]">
                        <img 
                            src="/assets/direction.svg" 
                            className={`transition-all duration-300 ${
                                isLoading ? 'animate-spin rotate-90' : 
                                displayCount >= collectionData.length ? 'rotate-[270deg]' : 'rotate-90'
                            }`} 
                        />
                    </div>
                    <span className="text-[#0099FE] text-[24px]">
                        {isLoading ? 'LOADING...' : displayCount >= collectionData.length ? 'SHOW LESS' : 'LOAD MORE'}
                    </span>
                </div>
                )}
            </div>

       </div>
    )
}
