
export const dailyNewsData = [
    {
        title: "Daily News Title",
        description: "Daily News Description",
        image: "/assets/avatar.png"
    },
    {
        title: "Daily News Title",
        description: "Daily News Description",
        image: "/assets/avatar2.png"
    },
    {
        title: "Daily News Title",
        description: "Daily News Description",
        image: "/assets/avatar3.png"
    },
    {
        title: "Daily News Title",
        description: "Daily News Description",
        image: "/assets/avatar.png"
    },
    {
        title: "Daily News Title",
        description: "Daily News Description",
        image: "/assets/avatar.png"
    },
    {
        title: "Daily News Title",
        description: "Daily News Description",
        image: "/assets/avatar2.png"
    },
    {
        title: "Daily News Title",
        description: "Daily News Description",
        image: "/assets/avatar3.png"
    },
    {
        title: "Daily News Title",
        description: "Daily News Description",
        image: "/assets/avatar.png"
    }
]

export const collectionData = [
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        value: 1289, 
        totalMintCount: 9999, 
        currentMintedCount: 9998
    },
    {
        image: "/assets/avatar2.png", 
        name: "PUNK_MsfdfERS", 
        value: 1289, 
        totalMintCount: 9999, 
        currentMintedCount: 9998
    },
    {
        image: "/assets/avatar3.png", 
        name: "PUNK_MsfdfERS", 
        value: 1289, 
        totalMintCount: 9999, 
        currentMintedCount: 9998
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        value: 1289, 
        totalMintCount: 9999, 
        currentMintedCount: 9998
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        value: 1289, 
        totalMintCount: 9999, 
        currentMintedCount: 9998
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        value: 1289, 
        totalMintCount: 9999, 
        currentMintedCount: 9998
    },
    {
        image: "/assets/avatar.png", 
        name: "PUNK_MsfdfERS", 
        value: 1289, 
        totalMintCount: 9999, 
        currentMintedCount: 9998
    },
]
