import * as React from "react";
import { useState } from "react";
import Input from "@/components/atomics/Input";
import TextArea from "@/components/atomics/text-area";
import StepLoad from "@/components/atomics/step-load";
import Direction from "@/components/atomics/direction";
import MetaData from "@/components/atomics/meta-data";
import { useNavigate } from "react-router-dom";
import { useCollection } from '@/context/CollectionContext';
import { BACKEND_URL } from "@/config";
import { CollectionDataUrls, CollectionMintEntry } from "@/types";
import { CollectionMintData, useMasterContract } from "@/hooks/useMasterContract";
import { useTonConnect } from "@/hooks/useTonConnect";
import Loading from "@/components/atomics/loading";
import Help from "@/components/atomics/help";
import ThirdStepHelp from "@/components/atomics/third-step-help";
import CollectionSuccessMessage from "@/components/atomics/collection-success-message";
import CollectionErrorMessage from "@/components/atomics/collection-error-message";
import { helpDescription } from "@/data/help";
import MotionModal from "@/components/atomics/motion-modal";
import { backend } from "@/services/backend";
import { useSnackbar } from "notistack";
import { Attribute } from "@/types";

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export default function ThirdStep() {
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const { ipfsUrls, formData: secondStepFormData } = useCollection();
    const masterContract = useMasterContract()
    const {network, wallet, sender } = useTonConnect();
    const [isLoading, setIsLoading] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [helpTitle, setHelpTitle] = useState('');
    const [collectionAddress, setCollectionAddress] = useState<string | null>(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const mintAction = async (collectionUrl: string, itemUrl: string) => {
        setIsLoading(true);
        const collectionData = await masterContract.newCollectionData();
        console.log(collectionData);
        try {
            if (!wallet) {
                throw new Error("Please connect your wallet first");
            }
            
            const royaltyPercent = Number(secondStepFormData['ROLALITY %']) || 0;
            const normalizedRoyalty = Math.min(Math.max(royaltyPercent, 0), 100);
            
            const dataToSend: CollectionMintData = {
                collectionDataUrl: collectionUrl,
                itemDataUrl: itemUrl,
                item_price: Number(secondStepFormData['PRICE IN TON']),
                mint_limit: Number(secondStepFormData['MINT LIMIT']),
                owner_address: wallet,
                royaltyParams: {
                    numenator: normalizedRoyalty * 10,
                    denumenator: 1000,
                    destination: wallet
                }
            }

            interface MintResult {
                success: boolean;
                address?: string;
                transaction?: any;
            }
            console.log(dataToSend);
            const result = await masterContract.mintCollection(dataToSend) as MintResult;
            setCollectionAddress(result.address || null);
            console.log(result, 'result');
            
            if (result.success && result.address) {
                // Check deployment status every 5 seconds for 2 minutes
                const maxAttempts = 24; // 2 minutes = 120 seconds / 5 seconds = 24 attempts
                let attempts = 0;
                
                while (attempts < maxAttempts) {
                    const isDeployed = await backend.getDeployStatus(result.address);
                    console.log(`Deployment check attempt ${attempts + 1}:`, isDeployed);
                    
                    if (isDeployed) {
                        setShowSuccessMessage(true);
                        return;
                    }
                    
                    await sleep(5000); // Wait 5 seconds before next check
                    attempts++;
                }
                
                // If we get here, deployment didn't complete within 2 minutes
                setShowErrorMessage(true);
                setErrorMessage("Collection deployment timed out after 2 minutes");
            } else {  
                setShowErrorMessage(true);
                setErrorMessage(result.transaction.error);  
                throw new Error("Transaction failed");
            }
        } catch (e) {
            console.error("Minting error:", e);
            if (e instanceof Error) {
                if (e.message.includes("Reject request")) {
                    snackbar.enqueueSnackbar("Transaction was rejected. Please check your wallet and try again.", {variant: "error", autoHideDuration: 1000});
                } else if (e.message.includes("UnknownError")) {
                    snackbar.enqueueSnackbar("An unknown error occurred. Please try again later or contact support.", {variant: "error", autoHideDuration: 1000});
                } else {
                    snackbar.enqueueSnackbar(`Failed to mint collection: ${e.message}`, {variant: "error", autoHideDuration: 1000});
                }
            } else {
                snackbar.enqueueSnackbar("An unexpected error occurred while minting the collection", {variant: "error", autoHideDuration: 1000});
            }
        } finally {
            setIsLoading(false);
        }
    }

    const saveCollectionEntry = async () => {
        setIsLoading(true);
        try {
            const collectionMintData: CollectionMintEntry = {
              collection: {
                name: formData.collectionName,
                description: formData.description,
                imageUrl: ipfsUrls.icon || "",
                backgroundUrl: ipfsUrls.cover || "",
                social_links: [],
              },
              item: {
                name: formData.nftName || formData.collectionName,
                description: formData.nftDescription || formData.description,
                image: ipfsUrls.nft || "",
                attributes: formData.metadata.map(({ key, value }) => ({
                  trait_type: key,
                  value: value
                })) as Attribute[],
              },
            };
            const response = await fetch(`${BACKEND_URL}files/loadcollection`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                entry: collectionMintData,
              }),
            });
            const result: CollectionDataUrls = await response.json();
            await mintAction(result.collectionFileUrl, result.itemFileUrl);
        } catch (error) {
            alert("Error saving collection: " + error);
        } finally {
            setIsLoading(false);
        }
    };

    const [formData, setFormData] = useState({
        collectionName: '',
        description: '',
        nftName: '',
        nftDescription: '',
        metadata: [{ key: '', value: '' }],
    });

    const handleChange = (field: string, value: string) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleAddPair = () => {
        // Check if the last pair has both key and value filled
        const lastPair = formData.metadata[formData.metadata.length - 1];
        if (!lastPair.key.trim() || !lastPair.value.trim()) {
            return;
        }

        setFormData(prev => ({
            ...prev,
            metadata: [...prev.metadata, { key: '', value: '' }]
        }));
    };

    const handleRemovePair = (index: number) => {
        if (formData.metadata.length === 1) {
            setFormData(prev => ({
                ...prev,
                metadata: [{ key: '', value: '' }]
            }));
            return;
        }
        setFormData(prev => ({
            ...prev,
            metadata: prev.metadata.filter((_, i) => i !== index)
        }));
    };

    const handleUpdatePair = (index: number, field: 'key' | 'value', value: string) => {
        setFormData(prev => {
            const newMetadata = [...prev.metadata];
            newMetadata[index][field] = value;
            return {
                ...prev,
                metadata: newMetadata
            };
        });
    };

    const isFormValid = () => {
        return formData.collectionName.trim() !== '' && formData.description.trim() !== '';
    };

    return (
        <div className="flex flex-col justify-center items-center pt-[20px] pb-[70px] relative">    
            <MotionModal isOpen={showHelp}>
                {helpTitle === 'STEP 3' ? (
                    <ThirdStepHelp 
                        className="w-full bg-black"
                        onClose={() => setShowHelp(false)} 
                        onOk={() => setShowHelp(false)}
                    />
                ) : (
                    <Help 
                        className="w-full bg-black"
                        onClose={() => setShowHelp(false)} 
                        onOk={() => setShowHelp(false)}
                        title={helpTitle}
                        description={helpDescription[helpTitle]}
                    />
                )}
            </MotionModal>
            <MotionModal isOpen={showSuccessMessage}>
                <CollectionSuccessMessage 
                    className="w-full bg-black"
                    collectionName={formData.collectionName}
                    onClose={() => setShowSuccessMessage(false)}
                    onClick={() => {navigate(`/collection/${collectionAddress}`)}}
                />
            </MotionModal>
            <MotionModal isOpen={showErrorMessage}>
                <CollectionErrorMessage 
                    className="w-full bg-black"
                    errorMessage={errorMessage}
                    onClose={() => setShowErrorMessage(false)}
                    onClick={() => {setShowErrorMessage(false)}}
                />
            </MotionModal>
            <header className="flex flex-col justify-center text-[32px] text-center">
                <div className="relative">
                    <span className={`${isLoading ? 'text-[#484848]' : 'text-white'}`}>STEP_3</span>
                    <div onClick={() => {setShowHelp(true); setHelpTitle('STEP 3')}} className={`${isLoading ? 'text-[#484848]' : 'text-[#0099FE]'} absolute top-[50%] left-[calc(50%+85px)] translate-x-[-50%] translate-y-[-50%] hover:cursor-pointer `}>
                        <span>?</span>
                    </div>
                </div>
                <span className={`${isLoading ? 'text-[#484848]' : 'text-white'}`}>CUSTOMIZE</span>          
            </header>
            <div className="w-full flex flex-col gap-[20px] pt-[30px] pb-[22px] pl-[30px] pr-[30px]">
                <Input 
                    label="Collection Name" 
                    placeholder="Simple collection"
                    essential={true} 
                    type="text"
                    isLoading= {isLoading}
                    value={formData.collectionName}
                    onChange={(e) => handleChange('collectionName', e.target.value)}
                    onHelpClick={() => {setShowHelp(true); setHelpTitle('COLLECTION NAME')}}
                />
                <TextArea 
                    label="Description"
                    placeholder="Simple description"
                    essential={true}
                    totalTextCount={700}
                    isLoading= {isLoading}
                    value={formData.description}
                    onChange={(e) => handleChange('description', e.target.value)}
                    onHelpClick={() => {setShowHelp(true); setHelpTitle('DESCRIPTION')}}
                />
                <Input 
                    label="NFT Name" 
                    placeholder="Simple collection"
                    type="text"
                    isLoading= {isLoading}
                    value={formData.nftName}
                    onChange={(e) => handleChange('nftName', e.target.value)}
                    onHelpClick={() => {setShowHelp(true); setHelpTitle('NFT NAME')}}
                />
                <TextArea 
                    label="NFT Description"
                    placeholder="Simple description"
                    totalTextCount={700}
                    isLoading= {isLoading}
                    value={formData.nftDescription}
                    onChange={(e) => handleChange('nftDescription', e.target.value)}
                    onHelpClick={() => {setShowHelp(true); setHelpTitle('NFT DESCRIPTION')}}
                />
                <MetaData 
                    label="Metadata"
                    pairs={formData.metadata}
                    onAddPair={handleAddPair}
                    isLoading= {isLoading}
                    onRemovePair={handleRemovePair}
                    onUpdatePair={handleUpdatePair}
                    onHelpClick={() => {setShowHelp(true); setHelpTitle('METADATA')}}
                />
            </div> 
 
            <div className="w-full relative flex flex-col justify-center items-center mt-[14px]">
                <span 
                    className={`text-[32px] ${isFormValid() || isLoading ? 'text-[#0099FE] hover:cursor-pointer' : 'text-[#484848]'}`}
                    onClick={() => isFormValid() && saveCollectionEntry()}
                > 
                    {isLoading ? 'CREATING' : 'CREATE'}
                </span>
                
                {!isLoading && (
                    <div className="absolute left-[60px] rotate-[180deg] text-[#0099FE] w-[17px] h-[29px]"
                    onClick={() => {navigate('/collection/create/2')}}>
                    <Direction 
                        className="w-full h-full"
                            color="#0099FE" />
                    </div>
                )}

                <Loading isLoading={isLoading} />
            </div>
        </div>
    )
}
