import * as React from "react";
import { useParams, Navigate } from "react-router-dom";
import Header from "@/components/template/dashboard-layout/header";
import Footer from "@/components/template/dashboard-layout/footer";
import CreateCollectionStep from "@/components/organisms/create-collection/step";

type StepParams = {
  step: string;
};

export default function CreateCollectionStepPage() {
    const { step } = useParams<StepParams>();
    const stepNumber = Number(step);

    // Validate step number
    if (isNaN(stepNumber) || stepNumber < 1 || stepNumber > 3) {
        return <Navigate to="/collection/create" replace />;
    }

    return (
       <>
            <Header />
            <CreateCollectionStep step={stepNumber} />
       </>
    )
}