import * as React from "react";
import { NFT } from "@/types/collectionType";
import ContractCard from "@/components/atomics/contract-card";
import Button from "@/components/atomics/button";
import { CollectionDataCache, CollectionFileData, ItemDataType, ItemFileData } from "@/types";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

interface NftProps {
    nftData: NFT;
    itemInfo: ItemDataType | null;
    nftContent: ItemFileData | null;
    collectionData: CollectionDataCache | null;
}

export default function Nft({ nftData, itemInfo, nftContent, collectionData }: NftProps) {
    const navigate = useNavigate();
    const [collectionInfo, setCollectionInfo] = useState<CollectionFileData | null>(null);

    useEffect(() => {
       const fetchData = async () => {
        const response = await fetch(collectionData?.data_url || '');
        const data = await response.json();
        setCollectionInfo(data);
       }
       fetchData();
    }, [collectionData]);

    const renderMetadata = () => {
        if (!nftContent?.attributes?.length) return null;
        
        return nftContent.attributes.map((item, index) => (
            <div 
                key={`metadata-${index}`} 
                className="border-b-2 border-[#757575] border-solid flex justify-between pt-[5px]"
            >
                <p className="w-[50%] text-[13px] text-[#757575] font-spline-sans-mono">
                    {item.trait_type}
                </p>
                <p className="w-[50%] text-[13px] text-[#757575] font-spline-sans-mono">
                    {item.value}
                </p>
            </div>
        ));
    };

    return (
        <article className="nft-container">
            <div className="w-full aspect-square">
                <img 
                    src={nftContent?.image} 
                    alt={nftContent?.name || 'NFT Image'} 
                    className="w-full h-full object-cover"
                />
            </div>
            <div className="px-[30px] pt-[20px]">
                <h4 className="text-[32px] max-w-[70%] truncate">
                    {nftContent?.name}
                </h4>
                <h5 className="text-[36px] text-[#0099FE]">
                    #{itemInfo?.index}
                </h5>
                <div>
                    <a className="text-[16px] underline cursor-pointer">
                        COLLECTION:
                    </a>
                    <span className="text-[16px] font-spline-sans-mono ml-2">
                        {collectionInfo?.name}
                    </span>
                </div>
                
                <ContractCard 
                    className="pt-[10px] pb-[10px] justify-start"
                    text="CONTRACT" 
                    length={10}
                    address={itemInfo?.collection_address || ""} 
                    onClick={() => navigate(`/collection/${itemInfo?.collection_address}`)}
                />
                <ContractCard 
                    className="pt-[10px] pb-[10px]"
                    length={14}
                    text="OWNER" 
                    address={itemInfo?.owner_address || ""} 
                    onClick={() => navigate(`/owner/${itemInfo?.owner_address}`)}
                />
                
                <section className="metadata-section">
                    <p className="text-[24px]">DESCRIPTION:</p>
                    <p className="text-[13px] font-spline-sans-mono text-[#757575]">
                        {nftContent?.description || 'No description available'}
                    </p>
                    
                    <div className="pt-[20px]">
                        <p className="text-[24px]">METADATA:</p>
                        {renderMetadata()}
                    </div>
                </section>
                
                <div className="flex justify-center">
                    <Button 
                        className="w-[calc(100%-40px)] text-[24px] mt-[34px]"
                        image="/assets/button.svg"
                        text="GO TO COLLECTION" 
                        onClick={() => navigate(`/collection/${itemInfo?.collection_address}`)}
                    />
                </div>
            </div>
        </article>
    );
}
