import * as React from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";
export default function Footer()  {
    return (
        <footer className='pl-[42px] pr-[42px] pt-[50px] pb-[50px] flex flex-col'>
            <div className='flex items-center justify-center gap-[26px]'>
                <a href="https://t.me/yourlink" aria-label="Telegram">
                    <FaTelegramPlane className='text-white w-[33px] h-[28px] hover:opacity-80' />
                </a>
                <a href="https://twitter.com/yourlink" aria-label="Twitter">
                    <FaXTwitter className='text-white w-[28px] h-[27px] hover:opacity-80' />
                </a>
                <a href="https://discord.gg/yourlink" aria-label="Discord">
                    <FaDiscord className='text-white w-[39px] h-[28px] hover:opacity-80' />
                </a>
            </div>
            <div className='flex flex-col pt-[40px] gap-[40px]'>     
                <div className="flex flex-col">
                    <h2 className='text-white font-silkscreen text-[32px] pt-[10px]'>EXPLORE</h2>
                    <a href="/" className="font-spline-sans-mono text-[32px] underline text-white hover:opacity-80">Home</a>
                    <a href="/features" className="font-spline-sans-mono text-[32px] underline text-white hover:opacity-80">Features</a>
                    <span className="font-spline-sans-mono text-[32px] text-[#555555]">RoadMap</span>  
                </div>
                <div className="flex flex-col">
                    <h2 className='text-white font-silkscreen text-[32px] pt-[10px]'>LEARN</h2>
                    <a href="/faq" className="font-spline-sans-mono text-[32px] underline text-white hover:opacity-80">FAQ</a>
                    <span className="font-spline-sans-mono text-[32px] text-[#555555]">Blog</span>
                    <span className="font-spline-sans-mono text-[32px] text-[#555555]">Resources</span>  
                </div>
                <div className="flex flex-col">
                    <h2 className='text-white font-silkscreen text-[32px] pt-[10px]'>CONTACT</h2>
                    <a href="https://t.me/yourlink" className="font-spline-sans-mono text-[32px] underline text-white hover:opacity-80">Telegram</a>
                    <a href="https://twitter.com/yourlink" className="font-spline-sans-mono text-[32px] underline text-white hover:opacity-80">Twitter</a>
                    <a href="mailto:your@email.com" className="font-spline-sans-mono text-[32px] underline text-white hover:opacity-80">Email</a>  
                </div>        
            </div>
            <div className="flex flex-col pt-[70px]">
                <p className="font-silkscreen text-[13px]">© 2024 SimpleNFT</p>
                <p className="font-silkscreen text-[13px]">Created by vorpal team, August 15, 2024</p>
            </div>
        </footer>
    )
}