import * as React from "react";

interface InputType {
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onHelpClick?: () => void;
  label?: string;
  essential?: boolean;
  type?: string;
  isLoading?: boolean;
  inputMode?: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';
  pattern?: string;
}

export default function Input({ 
  className, 
  placeholder, 
  value, 
  onChange, 
  onHelpClick,
  label, 
  essential, 
  type,
  inputMode,
  pattern,
  isLoading
}: InputType) {
    return (
        <div className={className}>
            <div className={`${isLoading ? 'text-[#484848]' : ''} relative text-[24px] text-center pb-[10px]`}>
                {essential && <span className={`${isLoading ? 'text-[#484848]' : 'text-[#FF0000]'}`}>*</span>}
                {label}
                {label && (
                    <div className="absolute right-0 top-0">
                        <span className={`${isLoading ? 'text-[#484848]' : 'text-[#0099FE]'}`} onClick={onHelpClick}>?</span>
                    </div>
                )}
            </div>
            <input  
                className={`${isLoading ? 'cursor-not-allowed text-[#484848]' : ''} w-full h-[40px] bg-[#232323] p-[10px] outline-none text-center text-[20px] font-spline-sans-mono placeholder-[#606060]`} 
                placeholder={placeholder} 
                value={value} 
                onChange={onChange}
                disabled={isLoading}
                inputMode={inputMode}
                pattern={pattern}
            />
        </div>      
    )
}