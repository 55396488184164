import * as React from "react";
import PictureAvatar from "@/components/molecules/mint/picture-avatar";
import MintButton from "@/components/molecules/mint/mint-button";
import ContractCard from "@/components/atomics/contract-card";
import Direction from "@/components/atomics/direction";
import { nftData } from "@/data/nft-data";
import NFTCard from "@/components/atomics/nft-card";
import OwnerView from "@/components/atomics/owner-view";
import { useNavigate } from "react-router-dom";
import { CollectionDataCache, CollectionFileData, ItemFileData } from "@/types";
import { useCollectionContract } from "@/hooks/useCollectionContract";
import { useMemo } from "react";
import clsx from "clsx";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import MotionModal from "@/components/atomics/motion-modal";
import NftSuccessMessage from "@/components/atomics/nft-success-message";   
import NftErrorMessage from "@/components/atomics/nft-error-message";
import { backend } from "@/services/backend";
import { useSnackbar } from "notistack";
interface MintProps {
    collection: string | undefined,
    collectionInfo: CollectionDataCache;
    // collectionMasterInfo: CollectionMasterStandardData;
  }

// Constants for better maintainability
const INITIAL_DISPLAY_COUNT = 8;
const LOAD_MORE_INCREMENT = 4;
const LOADING_DELAY = 300;
const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export default function Mint({collection, collectionInfo }: MintProps) {
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [metadata, setMetadata] = React.useState<any | null>(null);
    const [itemMetadata, setItemMetadata] = React.useState<ItemFileData | null>(null);
    const [isMinting, setIsMinting] = React.useState(false);

    const fetchMetadata = async (collectionUrl: string, itemUrl: string) => {
        try {
            const collectionResponse = await fetch(collectionUrl);
            const collectionData = await collectionResponse.json();
            setMetadata(collectionData);

            const itemResponse = await fetch(itemUrl);
            const itemData = await itemResponse.json();
            setItemMetadata(itemData);
        } catch (error) {
            console.error('Error fetching metadata:', error);
        }
    };

    // Add useEffect to fetch metadata when component mounts
    useEffect(() => {
        if (collectionInfo?.data_url && collectionInfo?.item_data_url) {
            fetchMetadata(collectionInfo.data_url, collectionInfo.item_data_url);
        }
    }, [collectionInfo?.data_url, collectionInfo?.item_data_url]);
    
    const { mint } = useCollectionContract();
    
    const mintNft = async () => {
        if (!collection) {
            console.error('Collection is undefined');
            return;
        }
        try {
            setIsMinting(true);
            const result = await mint(collection);
            console.log(result);
            if(result) {
                // Check deployment status every 5 seconds for up to 2 minutes
                const MAX_ATTEMPTS = 24; // 2 minutes = 120 seconds / 5 seconds = 24 attempts
                let attempts = 0;
                if(collectionInfo?.next_item_address) { 
                    while (attempts < MAX_ATTEMPTS) {
                        const isDeployed = await backend.getDeployStatus(collectionInfo?.next_item_address);
                    if (isDeployed) {
                        setIsMinting(false);
                        setShowSuccessMessage(true);
                        return;
                    }
                    await sleep(5000); // Wait 5 seconds before next check
                    attempts++;
                }
                }
                
                // If we get here, deployment didn't complete within 2 minutes
                setIsMinting(false);
                setShowErrorMessage(true);
                setErrorMessage("Deployment timeout after 2 minutes!");
            } else {
                setShowErrorMessage(true);
                setIsMinting(false);
            }
        } catch (error) {
            if (error.message.includes('Reject request')) {
                console.warn('Transaction was cancelled by the user.');
                snackbar.enqueueSnackbar('Transaction was cancelled by the user.', {variant: "error", autoHideDuration: 1000});
                // Optionally, you can set a specific error message for user cancellation
                setErrorMessage('Transaction was cancelled.');
            } else {
                console.error('Minting failed:', error);
                setErrorMessage('An unexpected error occurred.');
            }
            setShowErrorMessage(true);
        } finally {
            setIsMinting(false);
        }
    }

    const [state, setState] = React.useState({
        displayCount: INITIAL_DISPLAY_COUNT,
        isLoading: false,
        isOwnerView: false
    });

    // Memoize stats calculation
    const stats = useMemo(() => ({
        name: metadata?.name || '',
        description: metadata?.description || '',
        background: metadata?.cover_image || '',
        image: metadata?.image || '',
        nft_image: itemMetadata?.image || '',
        minted_nfts: collectionInfo?.next_item_index || 0,
        contract_address: collectionInfo?.address || '',
        owner_address: collectionInfo?.owner || '',
        nextItemAddress: collectionInfo?.next_item_address || '',
        total: collectionInfo?.mint_limit || 0,
        price: (collectionInfo?.price || 0) / 1000000000,
        earned: (collectionInfo?.next_item_index || 0) * ((collectionInfo?.price || 0) / 1000000000),
        available: collectionInfo?.mint_limit > 0 
            ? (collectionInfo?.mint_limit || 0) - (collectionInfo?.next_item_index || 0)
            : 0
    }), [metadata, itemMetadata, collectionInfo]);

    // Extract view components for better organization
    const StatDisplay = ({ label, value, unit }: { label: string; value: number; unit: string }) => (
        <div className="flex flex-col items-center justify-center">
            <span className="text-[24px] text-[#0099FE]">{label}</span>
            <span className="text-[32px]">{value}</span>
            <span className="text-[20px] text-[#0099FE]">{unit}</span>
        </div>
    );

    const handleLoadMore = React.useCallback(() => {
        setState(prev => ({ ...prev, isLoading: true }));
        
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                isLoading: false,
                displayCount: prev.displayCount >= nftData.length 
                    ? INITIAL_DISPLAY_COUNT 
                    : Math.min(prev.displayCount + LOAD_MORE_INCREMENT, nftData.length)
            }));
        }, LOADING_DELAY);
    }, []);

    const handleViewToggle = React.useCallback(() => {
        setState(prev => ({ ...prev, isOwnerView: !prev.isOwnerView }));
    }, []);

    // Add this helper function near the top of the component
    const generateMintedNFTs = (count: number) => {
        return Array.from({ length: count }, (_, index) => ({
            id: `#${index + 1}`,
            image: itemMetadata?.image || '',
            name: `${metadata?.name || ''} `,
            ownerAddress: collectionInfo?.owner || ''
        }));
    };

    // Replace the displayedNFTs memo with this
    const displayedNFTs = useMemo(() => {
        const mintedNFTs = generateMintedNFTs(stats.minted_nfts);
        return mintedNFTs.slice(0, state.displayCount);
    }, [state.displayCount, stats.minted_nfts, itemMetadata, metadata]);
  
    // console.log(stats.nextItemAddress,collectionInfo, 'stats.nextItemAddress');

    return (
        <div className="w-full">
             <OwnerView 
                    isOwnerView={state.isOwnerView}
                   handleViewToggle={handleViewToggle}
                />
             <div className="relative w-full h-[180px]">
                <img src={stats.background} alt="background" />
                <div className="w-full absolute left-[50%] translate-x-[-50%] translate-y-[-80px] flex flex-col items-center justify-center">
                    <PictureAvatar className="w-[160px]" image={stats.image} />
                    <div className="text-[32px]">
                        <span>{stats.name}</span>
                    </div>
                    <div className="text-[24px] text-[#6D6D6D]">
                        <span>ROYALTY: 45%</span>
                    </div>
                </div>
             </div>
             <div className="relative">
                <MotionModal isOpen={showSuccessMessage}>
                    <NftSuccessMessage 
                        className="w-full bg-black"
                        avatar={stats.image}
                        index={stats.minted_nfts}
                        collectionName={stats.name}
                        onClose={() => setShowSuccessMessage(false)}
                        onClick={() => {navigate(`/nft/${stats.nextItemAddress}`)}}
                    />
                </MotionModal>
                <MotionModal isOpen={showErrorMessage}>
                    <NftErrorMessage 
                        className="w-full bg-black"
                        avatar={stats.image}
                        index={stats.minted_nfts}
                        collectionName={stats.name}
                        errorMessage="Error message"
                        onClose={() => setShowErrorMessage(false)}
                        onClick={() => setShowErrorMessage(false)}
                    />
                </MotionModal>
             </div>

             <div className="px-[30px]">
                {!state.isOwnerView ? (
                    <div className="w-full h-[310px] pt-[183px] flex justify-between opacity-100 transition-all duration-500 ease-in-out">
                        <div className="w-[36%] aspect-square relative">
                            <img src={stats.nft_image} alt="mint" />
                            <div className="absolute z-[2] top-[0px] w-full h-full">
                                <img src="/assets/avatar-bg.png" alt="mint" />
                            </div>
                        </div>
                        <MintButton 
                            className="w-[60%]" 
                            price={stats.price} 
                            onClick={mintNft}
                            isLoading={isMinting} 
                        />
                    </div>
                ) : (
                    <div className="h-[310px] flex flex-col items-center justify-center pt-[183px] opacity-100 transition-all duration-500 ease-in-out">
                        <span className="text-[30px] text-[#0099FE]">EARNED</span>
                        <span className="text-[40px]">{stats.earned}</span>
                        <span className="text-[30px] text-[#0099FE]">TON</span>
                    </div>
                )}
               
                <div className="flex justify-center gap-[10px] pt-[30px]">
                    <StatDisplay label="TOTAL" value={stats.total} unit="NFT" />
                    <StatDisplay label="MINTED" value={stats.minted_nfts} unit="NFT" />
                    <StatDisplay label="AVAILABLE" value={stats.available} unit="NFT" />
                </div>

                <div className="pt-[30px]">
                    <ContractCard 
                        className="w-full pt-[10px] pb-[10px]" 
                        length={8} 
                        text="CONTRACT" 
                        address={stats.contract_address}
                        onClick={() => {}}
                    />
                    <ContractCard   
                        className="w-full py-[10px]" 
                        length={8} 
                        text="OWNER" 
                        address={stats.owner_address} 
                        onClick={() => {navigate(`/owner/${stats.owner_address}`)}}
                        />
                </div>

                <div className="flex flex-col">
                    <span className="text-[24px]">DESCRIPTION:</span>
                    <p className="font-spline-sans-mono text-[13px]  text-[#757575]">
                        {stats.description}
                    </p>
                </div>
                <div className="pt-[20px] pb-[23px] flex pr-[1px] items-end justify-end">
                    <div className="right-[10px] flex items-center justify-center gap-[10px]">
                        <span className="text-[#0099FE] text-[24px]">MORE</span>
                        <Direction color="#0099FE" className="w-[11px] h-[19px] rotate-[90deg]"/>
                    </div>
                </div>
             </div>

             <div className="w-full bg-white pt-[20px] pb-[20px] px-[30px]">
                <div className="pb-[23px]">
                    <span className="text-[30px] text-black">ALREADY CREATED</span>
                </div>
                <div className="w-full grid grid-cols-4 gap-[10px] transition-all duration-500 ease-in-out">
                    {displayedNFTs.map((item, index) => (
                        <NFTCard 
                            key={`nft-${item.id}-${index}`}
                            className={clsx(
                                'w-full flex-shrink-0 transition-all duration-300 ease-in-out',
                                state.isLoading && 'animate-pulse opacity-60',
                                !state.isLoading && 'opacity-100'
                            )}
                            {...item}
                        />
                    ))}
                </div>
                {stats.minted_nfts > INITIAL_DISPLAY_COUNT && (
                    <div 
                        className={`pt-[17px] flex justify-center flex-col items-center cursor-pointer ${
                            state.isLoading ? 'animate-bounce' : 'hover:scale-105'
                        } transition-all duration-300`}
                        onClick={handleLoadMore}
                    >
                        <div className="w-[19px] h-[19px]">
                            <img 
                                src="/assets/direction.svg" 
                                className={`transition-all duration-300 ${
                                    state.isLoading ? 'animate-spin rotate-90' : 
                                    state.displayCount >= stats.minted_nfts ? 'rotate-[270deg]' : 'rotate-90'
                                }`} 
                            />
                        </div>
                        <span className="text-[#0099FE] text-[24px]">
                            {state.isLoading ? 'LOADING...' : 
                             state.displayCount >= stats.minted_nfts ? 'SHOW LESS' : 'LOAD MORE'}
                        </span>
                    </div>
                )}
             </div>

        </div>
    )
}
