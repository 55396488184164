import * as React from "react";

export default function CreateCollectionButton({ 
    className, 
    onClick 
}: { 
    className: string;
    onClick?: () => void;
}) {
    return (
        <div 
            onClick={onClick}
            className={`${className} left-1/2 -translate-x-1/2 -translate-y-1/2 aspect-square flex items-center justify-center relative hover:cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out`}
        >
            <img src={"/assets/create-collection-button.svg"} alt="Create Collection" />
            <div className="font-silkscreen flex flex-col text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <p className="text-[32px]">CREATE</p>
                <p className="text-[19px]">COLLECTION</p>
            </div>
        </div>
    )
}