import * as React from "react";

export default function Direction({
  className,
  color,
  onClick
}: {
  className?: string,
  color?: string,
  onClick?: (event: React.MouseEvent) => void
}) {
   return (
      <div 
        className={`${className} flex items-center justify-center relative`}
        onClick={onClick}
      >
        <svg className="w-full h-full" width="17" height="29" viewBox="0 0 17 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 5.8H5.66667V11.6H11.3333V17.4H5.66667V23.2H0V29H5.66667V23.2H11.3333V17.4H17V11.6H11.3333V5.8H5.66667V0H0V5.8Z" fill={color ? color : '#484848'}/>
        </svg>
        </div>
    )
}