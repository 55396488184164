import * as React from "react";
import { useEffect, useState } from "react";
import { fetchData } from "@/types";
interface  CollectionCardType {
    onClick?: () => void;
    className: string,
    data_url?: string,
    image?: string
    name?: string
    value?: number
    totalMintCount?: number
    currentMintedCount?: number
}

export default function CollectionCard({className, data_url, image, name, value, totalMintCount, currentMintedCount, onClick }: CollectionCardType) {
    const [collectionData, setCollectionData] = useState<any | null>(null);
    useEffect(() => {
        if (data_url) {
            fetchData(data_url).then((data) => {
                setCollectionData(data);
            });
        }
    }, [data_url])
    
    const formatName = (name: string) => {
        if (name.length <= 9) return name;
        return `${name.slice(0, 4)}...${name.slice(-3)}`;
    };



    return (
        <div className={className} onClick={onClick}>
            <div className="w-full aspect-square">
                <img src={collectionData?.image || image} alt={name || ''} />
            </div>
            <h1 className='font-silkscreen text-[20px] text-black'>{formatName(collectionData?.name || name || '')}</h1>
            <p className='font-silkscreen text-[20px] --bold text-[#0099FE]'>{value} TON</p>
            <p className='text-[16px] text-black'>{currentMintedCount}/{totalMintCount}</p>
        </div>
    )
}