import * as React from "react";
import Button from "./button";

interface HelpProps {
    className?: string;
    title?: string;
    description?: string;
    onClose: () => void;
    onOk: () => void;
}

export default function FirstStepHelp({className, title, description, onClose, onOk}: HelpProps) {
    return (
        <div className={`${className}`}>
            <header className="relative flex justify-center items-center pl-[20px] pr-[20px] pt-[12px] pb-[12px] bg-[#0099FE] ">
                <div className="text-[33px]">
                    <span >INFORMATION</span>     
                </div>
                <div className="absolute right-[20px] w-[29px] h-[29px] hover:cursor-pointer" onClick={onClose}>
                    <img src="/assets/cancel.svg" alt="cancel" />
                </div>
            </header>
            <main className="pt-[28px] pb-[28px] pl-[54px] pr-[54px]">
                <h1 className="text-[28px] text-[#0099FE] font-spline-sans-mono">STEP 1</h1>
                <p className="text-[21px] font-spline-sans-mono pb-[20px]">
                    Upload 3 images: <span className="text-[#0099FE]">Cover</span> for the collection, <span className="text-[#0099FE]">icon</span> for the collection and the main picture for the <span className="text-[#0099FE]">NFTs</span>. Then click on "Load Data".
                </p>
                {/* <p className="pt-[30px] pb-[20px] text-[21px] font-spline-sans-mono">
                   <span className="text-red-500">ATTENTION!</span>  All uploaded files cannot be changed later. They are uploaded to IPFS - a decentralized storage protocol that ensures the immutability and availability of all uploaded data. 
                </p> */}
                <Button 
                    className="w-full  custom-aspect-320-63 text-[24px] hover:cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out" 
                    image="/assets/button.svg" 
                    text="OK" 
                    onClick={() => {onOk()}}
                />
            </main>
        </div>
    )
}