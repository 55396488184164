import * as React from "react";
import { nftData } from "@/data/nft-data";
import OwnerNFTCard from "@/components/atomics/owner-nft-card";
import { useNavigate } from "react-router-dom";
import { OwnerNftData } from "@/types";
import { sleep } from "@/utils/common";

interface OwnerNftType {
    itemsData: OwnerNftData[];
}

export default function OwnerNft({ itemsData }: OwnerNftType) {
    const navigate = useNavigate();
    const [displayCount, setDisplayCount] = React.useState(8);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleLoadMore = () => {
        setIsLoading(true);
        setTimeout(() => {
            if (displayCount <= itemsData.length) {
                setDisplayCount(8);
            } else {
                setDisplayCount(prev => Math.min(prev + 4, itemsData.length));
            }
            setIsLoading(false);
        }, 300);
    };

    return (
      <div className="w-full pt-[20px] pb-[20px] px-[30px]">
            <div className="pb-[23px]">
                <span className="text-[30px]">My NFT</span>
            </div>
            <div className="w-full grid grid-cols-4 gap-[18px] transition-all duration-500 ease-in-out">
                {itemsData.slice(0, displayCount).map((item, index) => (
                    <OwnerNFTCard 
                        key={index}
                        className={`w-full flex-shrink-0 transition-all duration-300 ease-in-out ${
                            isLoading ? 'animate-pulse opacity-60' : 'opacity-100'
                        }`}
                        onClick={() => navigate(`/nft/${item.address}`)}
                        item={item}                
                    />
                ))}
            </div>
            {itemsData.length > displayCount && (
            <div 
                className={`pt-[17px] flex justify-center flex-col items-center cursor-pointer ${
                    isLoading ? 'animate-bounce' : 'hover:scale-105'
                } transition-all duration-300`}
                onClick={handleLoadMore}
            >
                <div className="w-[19px] h-[19px]">
                    <img 
                        src="/assets/direction.svg" 
                        className={`transition-all duration-100 ${
                            // isLoading ? 'animate-spin rotate-90' : 
                            displayCount >= itemsData.length ? 'rotate-[270deg]' : 'rotate-90'
                        }`} 
                    />
                </div>
                <span className="text-[#0099FE] text-[24px]">
                    {isLoading ? 'LOADING...' : displayCount >= itemsData.length ? 'SHOW LESS' : 'LOAD MORE'}
                </span>
                </div>
            )}  
        </div>

    )
}