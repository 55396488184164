import * as React from "react";
import { useState } from "react";
import { BiCopy, BiCheck } from "react-icons/bi";

interface ContractCardType {
    className: string;
    text: string;
    address: string;
    length?: number;
    onClick?: () => void;
}

export default function ContractCard({ className, text, address, length , onClick }: ContractCardType) {
    const [copied, setCopied] = useState(false);

    const formatAddress = (addr: string) => {
        if (addr.length > 14) {
            return `${addr.slice(0, length)}...${addr.slice(-4)}`;
        }
        return addr;
    };

    const handleCopy = async () => {
        await navigator.clipboard.writeText(address);
        setCopied(true);
        setTimeout(() => setCopied(false), 400);
    };

    return (
        <div className={`${className} flex justify-between gap-[20px] relative`}>
            <div className="flex items-center gap-[5px]">
                <div className="text-[16px]">
                    <a className="hover:text-blue-500 underline cursor-pointer" onClick={onClick}>{text}:</a>
                </div>
                <div className={`flex justify-start font-spline-sans-mono text-[16px] --light ${copied ? 'text-[#0099FE]' : 'text-white'}`}>
                   {formatAddress(address)}
                </div>
            </div>
            <div className="flex items-center font-spline-sans-mono">
                {copied && (
                    <span className="absolute w-full top-[-16px] left-[0px] text-center text-[#0099FE]">
                        COPIED!
                    </span>
                )}
                <BiCopy onClick={handleCopy} className={`cursor-pointer w-[24px] h-[24px] ${copied ? 'text-[#0099FE]' : 'text-white'}`} />
            </div>
        </div>
    );
}