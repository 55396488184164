import * as React from "react";

interface OwnerAvatarType {
    className?: string;
    image: string;
    onImageChange?: (file: File) => void;
    maxResolution?: { width: number; height: number };
    maxFileSize?: number;
}

export default function OwnerAvatar({ className, image, onImageChange, maxResolution, maxFileSize }: OwnerAvatarType) {
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && onImageChange) {
            onImageChange(file);
        }
    };

    return (
        <div className={`${className} relative aspect-square cursor-pointer`} onClick={handleClick}>
            <img className="rounded-full" src={image || "/assets/avatar.png"} alt="avatar" />
            <div className="absolute w-[calc(100%+14px)] aspect-square z-[2] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                <img src="/assets/circle-bg.png" alt="avatar" />
            </div>
        </div>
    );
}

