import * as React from "react";
import { useState } from "react";
import Input from "@/components/atomics/Input";
import Direction from "@/components/atomics/direction";
import StepLoad from "@/components/atomics/step-load";
import SelectInput from "@/components/atomics/select-input";
import { inputData } from "@/data/input-data";
import { useNavigate } from "react-router-dom";
import { useCollection } from '@/context/CollectionContext';
import Help from "@/components/atomics/help";
import { helpDescription } from "@/data/help";
import MotionModal from "@/components/atomics/motion-modal";
export default function CreateCollectionSecondStep() {  
    const navigate = useNavigate();
    const { formData: contextFormData, setFormData: setContextFormData } = useCollection();
    const [showHelp, setShowHelp] = useState(false);
    const [helpTitle, setHelpTitle] = useState("");
    const [formData, setFormData] = useState({
        ...inputData.reduce((acc, input) => ({
            ...acc,
            [input.label]: input.value
        }), {}),
        SBT: false,
        ...contextFormData
    });

    React.useEffect(() => {
        setContextFormData(formData);
    }, [formData, setContextFormData]);

    const handleInputChange = (label: string, value: string | boolean) => {
        let processedValue = value;
        
        if (label === 'MINT LIMIT' && typeof value === 'string') {
            // Remove any non-numeric characters
            processedValue = value.replace(/[^\d]/g, '');
            // Allow empty string during typing
            processedValue = processedValue;
        }
        
        if (label === 'ROYALTY %' && typeof value === 'string') {
            // Remove any non-numeric characters
            processedValue = value.replace(/[^\d]/g, '');
            // Ensure value is between 0-100
            const numValue = parseInt(processedValue as string) || 0;
            processedValue = numValue > 100 ? '100' : processedValue;
        }

        if (label === 'PRICE IN TON' && typeof value === 'string') {
            // Allow only numbers and one decimal point
            processedValue = value.replace(/[^\d.]/g, '');
            // Ensure only one decimal point
            const parts = processedValue.split('.');
            if (parts.length > 2) {
                processedValue = parts[0] + '.' + parts.slice(1).join('');
            }
        }

        setFormData(prev => ({
            ...prev,
            [label]: processedValue
        }));
    };

    const isPriceValid = () => {
        const price = parseFloat(formData['PRICE IN TON']);
        const royalty = parseInt(formData['ROYALTY %']);
        const mintLimit = parseInt(formData['MINT LIMIT']);
        
        return price > 0 && 
               royalty >= 0 && 
               royalty <= 100 && 
               mintLimit >= 0;
    };

    return (
        <div className="w-full flex flex-col justify-center items-center pt-[20px] pb-[70px] relative">
            <MotionModal isOpen={showHelp}>
                <Help 
                    className="w-full bg-black"
                    onClose={() => setShowHelp(false)} 
                    onOk={() => setShowHelp(false)}
                    title={helpTitle}
                    description={helpDescription[helpTitle]}
                />
            </MotionModal>
            <header className="flex flex-col justify-center text-[32px] text-center">
                <div className="relative">
                    <span>STEP_2</span>
                    <div className="absolute top-[50%] left-[calc(50%+85px)] translate-x-[-50%] translate-y-[-50%] text-[#0099FE] hover:cursor-pointer" onClick={() => {setShowHelp(true); setHelpTitle('Step2')}}>
                        <span>?</span>
                    </div>
                </div>
                <span> SETUP COLLECTION</span>          
            </header>
            <div className="w-full flex flex-col gap-[20px] pt-[30px] pb-[22px] pl-[30px] pr-[30px]">
                {inputData.map((input, index) => (
                    <Input 
                        key={index} 
                        label={input.label} 
                        placeholder={input.placeholder} 
                        essential={input.essential} 
                        type={input.label === 'MINT LIMIT' || input.label === 'ROYALTY %' ? 'number' : input.type}
                        inputMode={input.label === 'MINT LIMIT' || input.label === 'ROYALTY %' ? 'numeric' : undefined}
                        pattern={input.label === 'MINT LIMIT' || input.label === 'ROYALTY %' ? '[0-9]*' : undefined}
                        value={formData[input.label]}
                        onChange={(e) => handleInputChange(input.label, e.target.value)}
                        onHelpClick={() => {setShowHelp(true); setHelpTitle(input.label)}}
                    />
                ))}
                <SelectInput 
                    label="SBT" 
                    essential={false}
                    value={formData.SBT}
                    onChange={(value) => handleInputChange('SBT', value)}
                    onHelpClick={() => {setShowHelp(true); setHelpTitle('SBT')}}
                />
            </div>
            <StepLoad currentIndex={1} totalIndex={3} />
            <div className="w-full relative flex justify-center mt-[14px]">
                <span 
                    className={`text-[32px] ${isPriceValid() ? 'text-[#0099FE]' : 'text-[#484848]'}`}
                    onClick={() => {isPriceValid() && navigate('/collection/create/3')}}
                    > NEXT </span>
                <div 
                    className={`absolute top-[50%] right-[60px] translate-y-[-50%] w-[17px] h-[29px] ${isPriceValid() ? 'text-[#0099FE]' : 'text-[#484848]'}` }
                    onClick={() => {isPriceValid() && navigate('/collection/create/3')}}
                >
                    <Direction 
                        className="w-full h-full"
                        color={isPriceValid() ? "#0099FE" : "#484848"} />
                </div>
                <div 
                    className="absolute top-[50%] left-[60px] translate-y-[-50%] rotate-[180deg] w-[17px] h-[29px] text-[#0099FE] hover:cursor-pointer"
                    onClick={() => {navigate('/collection/create/1')}}
                >
                    <Direction 
                        className="w-full h-full"
                        color="#0099FE" />
                </div>
            </div>
        </div>
    );
}   