import * as React from "react";
import { useState } from "react";
import CreateCollectionStepHeader from "./header";
import CreateCollectionFirstStep from "@/components/molecules/create-collection/step/firstStep";
import CreateCollectionSecondStep from "@/components/molecules/create-collection/step/secondStep";
import CreateCollectionThirdStep from "@/components/molecules/create-collection/step/thirdStep";
export default function CreateCollectionStep({step}: {step: number}) {
    const [currentStep, setCurrentStep] = useState(step);

    React.useEffect(() => {
        setCurrentStep(step)
    }, [step])
    return (
        <>
            <CreateCollectionStepHeader step={currentStep} />
            {currentStep === 1 && <CreateCollectionFirstStep />}
            {currentStep === 2 && <CreateCollectionSecondStep />}
            {currentStep === 3 && <CreateCollectionThirdStep />}
        </>
    )
}